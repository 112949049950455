<template>
  <div class="app-footer" data-testid="app-footer">
    <p class="app-footer-trademark">© {{ currentYear }} Körber</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppFooter',

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
});
</script>

<style scoped lang="scss">
.app-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $gray-25;
  height: 56px;
  padding: 17px;
  position: relative;
  z-index: 2;
  &-trademark {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: $gray-800;
    margin: 0;
  }
  &-license {
    font-weight: 700;
    font-size: 13px;
    line-height: 150%;
    color: $gray-800;
    margin: 0;
  }
}
</style>
