<template>
  <div class="wizard-step-editor" data-testid="wizard-step-editor">
    <div class="wizard-step-editor__col">
      <span class="wizard-step-editor__title">{{ title }}</span>
      <slot />
    </div>
    <AppButton
      class="wizard-step-editor__button"
      type="secondary"
      @click="handleButtonClick"
    >
      <IconPencil />
      {{ $t('labels.common.edit') }}
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import IconPencil from '@/assets/icons/icon-pencil.vue';
import { AppButton } from '@/plugins/commons';

export default defineComponent({
  name: 'AppWizardStepEditor',

  components: { AppButton, IconPencil },

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  emits: ['click'],

  methods: {
    handleButtonClick() {
      this.$emit('click');
    },
  },
});
</script>

<style scoped lang="scss">
.wizard-step-editor {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
}

.wizard-step-editor__col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.wizard-step-editor__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: $gray-800;
}

.wizard-step-editor__button :deep(.q-icon) {
  font-size: 15px;
  margin-right: 8px;
}
</style>
