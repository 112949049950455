<template>
  <div v-if="error" class="app-error-message" data-testid="app-error">
    <IconError alt="Error" class="app-error-message__icon" />

    <span
      :class="['app-error-message__text', `app-error-message__text-${size}`]"
      data-testid="app-error-message"
    >
      {{ error }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import IconError from '@icons/icon-error.vue';

export default defineComponent({
  name: 'AppErrorMessage',

  components: {
    IconError,
  },

  props: {
    error: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: undefined,
    },
  },
});
</script>

<style scoped lang="scss">
.app-error-message {
  gap: 6px;
  display: flex;
}

.app-error-message__icon {
  padding: 1px 0px 1px 1px;
  flex-shrink: 0;
  margin-top: 1px;
}

.app-error-message__text {
  color: $red-500;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  white-space: nowrap;
}

.app-error-message__text-M {
  font-size: 16px;
}
</style>
