<template>
  <div class="app-creation-layout">
    <div
      class="app-creation-layout__body"
      data-testid="app-creation-layout-body"
    >
      <div
        class="app-creation-layout__content"
        data-testid="app-creation-layout-content"
      >
        <div
          class="app-creation-layout__header"
          data-testid="app-creation-layout-header"
        >
          <slot name="header" />
        </div>
        <div
          class="app-creation-layout__main"
          data-testid="app-creation-layout-main"
        >
          <slot />
        </div>
      </div>
      <div
        v-if="sidebar"
        class="app-creation-layout__sidebar"
        data-testid="app-creation-layout-sidebar"
      >
        <slot name="sidebar" />
      </div>
    </div>
    <div
      class="app-creation-layout__footer"
      data-testid="app-creation-layout-footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppCreationLayout',

  props: {
    sidebar: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.app-creation-layout {
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.app-creation-layout__body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 104px;
}

.app-creation-layout__header {
  padding: 32px 48px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: $white;
}

.app-creation-layout__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.app-creation-layout__main > :deep(*) {
  margin: 0 48px;
}

.app-creation-layout__main {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 42px 0 24px;
  overflow: auto;
}

.app-creation-layout__sidebar {
  background-color: $gray-25;
  display: flex;
  flex-direction: column;
  width: 270px;
  overflow-y: auto;
  flex-shrink: 0;
}

.app-creation-layout__footer {
  padding: 24px 88px;
  background-color: $white;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px 0 0 0;
  flex-basis: 100%;
  gap: 24px;
  display: flex;
  justify-content: flex-end;
  z-index: 5;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 64px;
  height: fit-content;
}
</style>
