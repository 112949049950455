import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f73556e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "app-store-item-stock__tooltip",
  "data-testid": "store-item-stock"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppTooltip = _resolveComponent("AppTooltip")!

  return (_ctx.stock === 0)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(_ctx.computedClass)
      }, [
        _createVNode(_component_AppIcon, {
          class: "app-store-item-stock__icon",
          name: "error",
          size: "13px"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.computedLabel) + " ", 1),
        (_ctx.tooltip)
          ? (_openBlock(), _createBlock(_component_AppTooltip, {
              key: 0,
              anchor: "top middle",
              self: "bottom middle",
              offset: [5, 5]
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_AppIcon, {
                    class: "app-store-item-stock__icon",
                    name: "error",
                    size: "13px"
                  }),
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('tooltips.store.stock.noStock')) + " ", 1),
                    _createElementVNode("strong", null, _toDisplayString(_ctx.$t('tooltips.store.stock.noStockBold')), 1)
                  ])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 2))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createTextVNode(_toDisplayString(_ctx.computedLabel), 1)
      ], 64))
}