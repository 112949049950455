<script setup lang="ts" generic="T">
import {
  AppListFlexDirection,
  AppListFlexWrap,
} from '@/shared/types/components';
import { v4 } from 'uuid';

interface Props {
  items: T[];
  gap?: string;
  itemMargin?: string;
  itemPadding?: string;
  flexWrap?: AppListFlexWrap;
  flexDirection?: AppListFlexDirection;
}

withDefaults(defineProps<Props>(), {
  gap: 'unset',
  flexWrap: 'wrap',
  itemMargin: 'unset',
  itemPadding: 'unset',
  flexDirection: 'row',
});

const uuid: string = v4();

const getItemKey = (index: number): string => `item_${uuid}_${index}`;
</script>

<template>
  <div class="app-list">
    <template v-for="(item, index) in items" :key="getItemKey(index)">
      <slot name="item" class="app-list__item" :item="item" :index="index" />
    </template>
  </div>
</template>

<style scoped lang="scss">
.app-list {
  display: flex;
  gap: v-bind(gap);
  flex-wrap: v-bind(flexWrap);
  flex-direction: v-bind(flexDirection);
}

.app-list__item {
  margin: v-bind(itemMargin);
  padding: v-bind(itemPadding);
}
</style>
