<template>
  <transition
    name="app-collapsible"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @before-leave="onBeforeLeave"
    @leave="onLeave"
  >
    <div
      v-show="expanded"
      ref="wrapper"
      class="app-collapsible"
      data-testid="app-collapsible"
    >
      <div ref="inner" class="app-collapsible__inner">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppCollapsible',

  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
  },

  mounted() {
    if (this.expanded) {
      this.setWrapperHeightTo('auto');
    }
  },

  methods: {
    onEnter(el: HTMLElement) {
      this.setWrapperHeightTo(this.getContentHeight(), el);
    },

    onAfterEnter(el: HTMLElement) {
      this.setWrapperHeightTo('auto', el);
    },

    onBeforeLeave(el: HTMLElement) {
      this.setWrapperHeightTo(this.getContentHeight(), el);
    },

    onLeave(el: HTMLElement) {
      this.setWrapperHeightTo(0, el);
    },

    getContentHeight() {
      const inner = this.$refs.inner as HTMLElement;
      return inner.getBoundingClientRect().height;
    },

    setWrapperHeightTo(height: number | 'auto', el?: HTMLElement) {
      const element = el || (this.$refs.wrapper as HTMLElement);

      element.style.height =
        typeof height === 'number' ? `${height}px` : height;
    },
  },
});
</script>

<style lang="scss">
.app-collapsible {
  transition: all ease-in 300ms;
  height: 0;
  overflow: hidden;
}

.app-collapsible__inner {
  width: 100%;
}

.app-collapsible-enter-from,
.app-collapsible-leave-to {
  opacity: 0;
}
</style>
