
import { defineComponent } from 'vue';

const __default__ = defineComponent({
  name: 'AppSeparator',

  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    inset: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: String,
      default: undefined,
    },
    verticalMargin: {
      type: String,
      default: '5px',
    },
  },

  computed: {
    computedClass(): string {
      let result = 'app-separator';
      if (this.vertical) {
        result += ' app-separator--vertical';
      }
      return result;
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "985e4f6c": (_ctx.margin),
  "e1ac4140": (_ctx.verticalMargin)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__