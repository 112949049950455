<template>
  <q-menu v-bind="$props">
    <slot />
  </q-menu>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'AppDropdownMenu',
  props: {
    offset: {
      type: Array as PropType<Array<number>>,
      default: undefined,
    },
  },
});
</script>
