<template>
  <div :class="['app-reaction__reaction', getBackgroundColorReaction]">
    <AppTooltip
      v-if="valueAnswer !== noAnswers"
      anchor="top middle"
      self="bottom middle"
    >
      <div class="app-reaction__tooltip-container">
        <span class="app-reaction__tooltip app-reaction__tooltip--bold">
          {{
            $t('tooltips.survey.respondents', [
              valueAnswer,
              totalAnswers,
              getPercentageRespondents,
            ])
          }}
        </span>
        <span class="app-reaction__tooltip">
          {{ $t('tooltips.survey.selected') }}
        </span>
        <span class="app-reaction__tooltip">
          {{ $t('tooltips.survey.score', [score]) }}
        </span>
        <span class="app-reaction__tooltip">
          {{ $t('tooltips.survey.scale') }}
        </span>
      </div>
    </AppTooltip>
    <div class="app-react-emoji__content">
      <span class="app-reaction__number">
        {{ valueAnswer !== noAnswers ? valueAnswer : emptyValue }}
      </span>
      <component :is="getEmojiReaction" width="20px" height="20px" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, Component } from 'vue';
import AppTooltip from '@/components/app/AppTooltip/AppTooltip.vue';
import { ReactionType } from '@/shared/types/generic';
import IconEmojiBad from '@/assets/icons/icon-emoji-bad.vue';
import IconEmojiGood from '@/assets/icons/icon-emoji-good.vue';
import IconEmojiHappy from '@/assets/icons/icon-emoji-happy.vue';
import IconEmojiNormal from '@/assets/icons/icon-emoji-normal.vue';
import IconEmojiSad from '@/assets/icons/icon-emoji-sad.vue';

export default defineComponent({
  name: 'AppReaction',
  components: {
    AppTooltip,
    IconEmojiBad,
    IconEmojiGood,
    IconEmojiHappy,
    IconEmojiNormal,
    IconEmojiSad,
  },
  props: {
    reaction: {
      type: String as PropType<ReactionType>,
      default: ReactionType.NORMAL,
    },
    score: {
      type: Number,
      default: 0,
    },
    totalAnswers: {
      type: Number,
      default: 0,
    },
    valueAnswer: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      emptyValue: '--',
      noAnswers: -1,
    };
  },
  computed: {
    getPercentageRespondents(): string {
      return ((this.valueAnswer * 100) / this.totalAnswers).toFixed(1);
    },

    getEmojiReaction(): Component {
      switch (this.reaction) {
        case ReactionType.BAD:
          return IconEmojiBad;
        case ReactionType.SAD:
          return IconEmojiSad;
        case ReactionType.NORMAL:
          return IconEmojiNormal;
        case ReactionType.GOOD:
          return IconEmojiGood;
        case ReactionType.HAPPY:
          return IconEmojiHappy;
        default:
          return IconEmojiNormal;
      }
    },
    getBackgroundColorReaction(): string {
      switch (this.reaction) {
        case ReactionType.BAD:
          return 'app-reaction__background--red';
        case ReactionType.SAD:
          return 'app-reaction__background--orange';
        case ReactionType.GOOD:
          return 'app-reaction__background--blue';
        case ReactionType.HAPPY:
          return 'app-reaction__background--green';
        default:
          return 'app-reaction__background--gray';
      }
    },
  },
});
</script>

<style scoped lang="scss">
.app-reaction__reaction {
  width: 76px;
  min-height: 32px;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  gap: 8px;
}

.app-reaction__number {
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
}

.app-reaction__background--red {
  background: $red-25;
  color: $red-500;
}

.app-reaction__background--orange {
  background: $orange-25;
  color: $orange-500;
}

.app-reaction__background--blue {
  background: $blue-25;
  color: $blue-500;
}

.app-reaction__background--gray {
  background: $gray-25;
  color: $gray-600;
}

.app-reaction__background--green {
  background: $green-25;
  color: $green-700;
}

.app-reaction__tooltip-container {
  flex-direction: row;
  width: 291px;
}

.app-reaction__tooltip {
  font-size: 15px;
  line-height: 22px;
  margin-left: 2px;
  margin-right: 1px;
}

.app-reaction__tooltip--bold {
  font-weight: bold;
}

.app-react-emoji__content {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
}
</style>
