<template>
  <span class="app-tag">
    <slot />
  </span>
</template>

<style scoped lang="scss">
.app-tag {
  padding: 0 8px;
  margin-left: 0px;
  background: $primary-background;
  border-radius: 4px;
  font-size: 12px;
  color: $gray-800;
}
</style>
