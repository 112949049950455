<template>
  <q-field
    class="app-checkbox"
    data-testid="app-checkbox"
    :error="isError"
    :dense="dense"
    borderless
    no-error-icon
    bottom-slots
  >
    <template #control>
      <q-checkbox v-model="model" :label="label" v-bind="$attrs"></q-checkbox>
    </template>
    <template #error>
      <AppErrorMessage :error="error" />
    </template>
  </q-field>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppErrorMessage from '../AppErrorMessage/AppErrorMessage.vue';

export default defineComponent({
  name: 'AppCheckbox',

  components: { AppErrorMessage },

  props: {
    modelValue: {
      type: Boolean,
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(newValue: boolean) {
        this.$emit('update:modelValue', newValue);
      },
    },

    isError(): boolean {
      return (this.error?.length || 0) > 0;
    },
  },
});
</script>
<style scoped lang="scss">
:deep(.q-field__bottom--animated) {
  transform: translateY(50%);
}

.app-checkbox :deep(.q-field__control.text-negative .q-checkbox__inner) {
  color: $red-500;
}

.app-checkbox:not(.q-field--dense) :deep(.q-field__bottom) {
  font-size: 14px;
  margin-left: 40px;
}

.app-checkbox.q-field--dense :deep(.q-field__bottom) {
  font-size: 14px;
  margin-left: 28px;
}

.app-checkbox.q-field--with-bottom {
  padding-bottom: 10px;
}

:deep(.q-checkbox__inner--truthy),
:deep(.q-checkbox__inner--indet) {
  color: $primary-color;
}
</style>
