import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-009d325e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-reaction__tooltip-container" }
const _hoisted_2 = { class: "app-reaction__tooltip app-reaction__tooltip--bold" }
const _hoisted_3 = { class: "app-reaction__tooltip" }
const _hoisted_4 = { class: "app-reaction__tooltip" }
const _hoisted_5 = { class: "app-reaction__tooltip" }
const _hoisted_6 = { class: "app-react-emoji__content" }
const _hoisted_7 = { class: "app-reaction__number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTooltip = _resolveComponent("AppTooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-reaction__reaction', _ctx.getBackgroundColorReaction])
  }, [
    (_ctx.valueAnswer !== _ctx.noAnswers)
      ? (_openBlock(), _createBlock(_component_AppTooltip, {
          key: 0,
          anchor: "top middle",
          self: "bottom middle"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('tooltips.survey.respondents', [
              _ctx.valueAnswer,
              _ctx.totalAnswers,
              _ctx.getPercentageRespondents,
            ])), 1),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('tooltips.survey.selected')), 1),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('tooltips.survey.score', [_ctx.score])), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('tooltips.survey.scale')), 1)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.valueAnswer !== _ctx.noAnswers ? _ctx.valueAnswer : _ctx.emptyValue), 1),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getEmojiReaction), {
        width: "20px",
        height: "20px"
      }))
    ])
  ], 2))
}