import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_drawer = _resolveComponent("q-drawer")!

  return (_openBlock(), _createBlock(_component_q_drawer, _mergeProps({ ref: "appSidebar" }, _ctx.$props, {
    modelValue: _ctx.appSidebarOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.appSidebarOpen) = $event)),
    side: "right",
    class: "app-sidebar",
    overlay: "",
    "data-testid": "app-sidebar-drawer",
    behavior: "desktop",
    persistent: "",
    width: 576
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 16, ["modelValue"]))
}