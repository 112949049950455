import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLoading = _resolveComponent("AppLoading")!
  const _component_AppLoadingRow = _resolveComponent("AppLoadingRow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppLoading, { class: "app-table-loader__search" }),
    _createVNode(_component_AppLoading, { class: "app-table-loader__header" }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfRows, (element) => {
      return (_openBlock(), _createBlock(_component_AppLoadingRow, {
        key: element,
        "number-of-skeletons": _ctx.numberOfSkeletons,
        skeletons: _ctx.skeletons
      }, null, 8, ["number-of-skeletons", "skeletons"]))
    }), 128))
  ], 64))
}