<template>
  <template v-if="stock === 0">
    <span :class="computedClass">
      <AppIcon class="app-store-item-stock__icon" name="error" size="13px" />
      {{ computedLabel }}
      <AppTooltip
        v-if="tooltip"
        anchor="top middle"
        self="bottom middle"
        :offset="[5, 5]"
      >
        <div
          class="app-store-item-stock__tooltip"
          data-testid="store-item-stock"
        >
          <AppIcon
            class="app-store-item-stock__icon"
            name="error"
            size="13px"
          />
          <span>
            {{ $t('tooltips.store.stock.noStock') }}
            <strong>{{ $t('tooltips.store.stock.noStockBold') }}</strong>
          </span>
        </div>
      </AppTooltip>
    </span>
  </template>
  <template v-else>
    {{ computedLabel }}
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppTooltip from '@/components/app/AppTooltip/AppTooltip.vue';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';

export default defineComponent({
  name: 'AppStoreItemStatus',

  components: {
    AppTooltip,
    AppIcon,
  },

  props: {
    stock: {
      type: Number,
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    unlimitedLabel: {
      type: String,
      default: '-',
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClass() {
      let result = 'app-store-item-stock';
      if (this.tooltip) {
        result += ' app-store-item-stock--tooltip';
      }
      return result;
    },

    computedLabel() {
      if (this.stock === undefined || this.stock === null)
        return this.unlimitedLabel;
      return `${this.$n(this.stock)} ${this.label}`;
    },
  },
});
</script>

<style scoped lang="scss">
.app-store-item-stock {
  display: flex;
  align-items: center;
  gap: 7px;
  color: $red-500;
  font-weight: 700;
}
.app-store-item-stock--tooltip {
  cursor: pointer;
}
.app-store-item-stock__icon {
  color: $red-500;
}
.app-store-item-stock__tooltip {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 6px;
}
</style>
