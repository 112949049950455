<template>
  <q-form v-bind="$props">
    <slot />
  </q-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppForm',
});
</script>
