import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_date = _resolveComponent("q-date")!

  return (_openBlock(), _createBlock(_component_q_date, _mergeProps({
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    class: "app-date",
    "data-testid": "app-date",
    "no-unset": "",
    locale: _ctx.locale
  }, _ctx.$props), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 16, ["modelValue", "locale"]))
}