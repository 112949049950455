<template>
  <div class="app-header" data-testid="app-header">
    <div class="app-header__leading">
      <slot />
    </div>
    <div class="app-header__actions">
      <slot name="trailing" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppHeader',
});
</script>

<style scoped lang="scss">
.app-header {
  display: flex;
  margin-bottom: 32px;
  min-height: 32px;
  justify-content: space-between;
  position: relative;
  align-items: center;
  flex-shrink: 0;
}

.app-header__leading {
  display: flex;
  align-self: stretch;
  align-items: center;
}

.app-header__actions {
  position: absolute;
  right: 0;
}
</style>
