<template>
  <q-tooltip
    v-model="model"
    v-bind="$props"
    anchor="bottom right"
    self="center left"
    transition-show="fade"
    transition-hide="fade"
    :offset="[0, 0]"
    class="app-tooltip"
    data-testid="app-tooltip"
    :max-width="maxWidth"
  >
    <slot />
  </q-tooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppTooltip',

  props: {
    modelValue: {
      type: Boolean,
      default: undefined,
    },
    maxWidth: {
      type: String,
      default: '340px',
    },
  },

  emits: ['update:modelValue'],

  computed: {
    model: {
      get(): boolean | undefined {
        return this.$props.modelValue;
      },

      set(newValue: string) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
});
</script>

<style lang="scss">
.app-tooltip {
  color: $gray-800;
  padding: 10px;
  font-size: 14px;
  line-height: 19px;
  border-radius: 4px;
  background-color: $white;
  border: 1px solid $gray-400;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05);
}
</style>
