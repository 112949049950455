<template>
  <div class="app-label">
    <div
      data-testid="app-label__label"
      :class="[
        'app-label__label',
        `app-label__label--${size}`,
        { 'app-label__label--required': isRequired },
        { 'app-label__label--disabled': disabled },
        { 'app-label__label--withoutBold': withoutBold },
      ]"
    >
      <span>{{ label }}</span>
      <span v-if="hasAsterisk" class="app-label__label-asterisk">*</span>
    </div>
    <div v-if="hasInformation" class="app-label__information">
      <AppInformation>
        <slot name="information">{{ information }}</slot>
      </AppInformation>
    </div>
    <slot v-else name="trailing" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { matInfo } from '@quasar/extras/material-icons';
import { AppLabelSize } from '@/shared/types/generic';
import AppInformation from '../AppInformation/AppInformation.vue';

export default defineComponent({
  name: 'AppLabel',

  components: {
    AppInformation,
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    information: {
      type: String,
      default: undefined,
    },
    size: {
      type: String as PropType<AppLabelSize>,
      default: 'medium',
    },
    tooltipWidth: {
      type: String,
      default: undefined,
    },
    isRequired: Boolean,
    marginBottom: {
      type: String,
      default: '4px',
    },
    withoutBold: {
      type: Boolean,
      default: false,
    },
    hasAsterisk: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      matInfo,
    };
  },

  computed: {
    hasInformation() {
      return !!this.information || this.$slots.information;
    },
  },
});
</script>

<style scoped lang="scss">
.app-label {
  display: flex;
  align-items: center;
  margin-bottom: v-bind(marginBottom);
  gap: 4px;
}

.app-label__label {
  color: $gray-800;
  font-weight: 700;
}

.app-label__label--disabled {
  color: $gray-200;
  background-color: transparent;
}

.app-label__label--withoutBold {
  font-weight: 400;
}

.app-label__label--medium {
  font-size: 14px;
  line-height: 150%;
}

.app-label__label--large {
  font-size: 18px;
  line-height: 130%;
}

.app-label__label--required:after {
  content: '*';
}

.app-label__information {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-label__information__icon {
  transform: scale(1.2);
  color: $gray-400;
}

.app-label__label-asterisk {
  color: $gray-500;
  font-weight: 400;
}
</style>
