import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_AppErrorMessage = _resolveComponent("AppErrorMessage")!
  const _component_q_field = _resolveComponent("q-field")!

  return (_openBlock(), _createBlock(_component_q_field, {
    class: "app-checkbox",
    "data-testid": "app-checkbox",
    error: _ctx.isError,
    dense: _ctx.dense,
    borderless: "",
    "no-error-icon": "",
    "bottom-slots": ""
  }, {
    control: _withCtx(() => [
      _createVNode(_component_q_checkbox, _mergeProps({
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
        label: _ctx.label
      }, _ctx.$attrs), null, 16, ["modelValue", "label"])
    ]),
    error: _withCtx(() => [
      _createVNode(_component_AppErrorMessage, { error: _ctx.error }, null, 8, ["error"])
    ]),
    _: 1
  }, 8, ["error", "dense"]))
}