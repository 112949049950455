<template>
  <AppLoading class="app-table-loader__search" />
  <AppLoading class="app-table-loader__header" />
  <AppLoadingRow
    v-for="element in numberOfRows"
    :key="element"
    :number-of-skeletons="numberOfSkeletons"
    :skeletons="skeletons"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppLoadingRow from '../AppLoadingRow/AppLoadingRow.vue';
import AppLoading from '../AppLoading/AppLoading.vue';
import skeletons from '.';

export default defineComponent({
  name: 'AppTableLoader',
  components: { AppLoadingRow, AppLoading },
  props: {
    numberOfRows: { type: Number, required: true },
  },

  data() {
    return {
      skeletons,
    };
  },

  computed: {
    numberOfSkeletons(): number {
      return this.skeletons.length;
    },
  },
});
</script>

<style scoped lang="scss">
.app-table-loader__search {
  max-width: 10%;
  height: 32px;
  border-radius: 4px;
  background: $neutral-gradient-light;
  margin-bottom: 16px;
}

.app-table-loader__header {
  background: $gray-25;
  height: 32px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
</style>
