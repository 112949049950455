<script setup lang="ts">
import { ChipListItem } from '@/shared/types/components';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import AppChip from '../AppChip/AppChip.vue';

interface Props {
  items: ChipListItem[];
}

const props = defineProps<Props>();

const listIsExpanded = ref<boolean>(false);

const listIsExpandable = computed<boolean>(() => props.items.length > 2);

const expandCollapseChipLabel = computed<string>(() =>
  listIsExpanded.value ? 'labels.common.collapse' : 'labels.common.expand',
);

function handleExpandCollapseChipClick() {
  listIsExpanded.value = !listIsExpanded.value;
}

const { t } = useI18n();
</script>

<template>
  <div class="app-chip-list" :data-expanded="listIsExpanded">
    <AppChip
      v-for="item in items"
      :key="item.label"
      class="app-chip-list__chip"
    >
      <span :class="['app-chip-list__chip__label', item?.class]">
        {{ item.label }}
      </span>
    </AppChip>
    <AppChip
      v-if="listIsExpandable"
      class="app-chip-list__expand-collapse-chip"
      clickable
      @click="handleExpandCollapseChipClick"
    >
      <b>{{ t(expandCollapseChipLabel) }}</b>
    </AppChip>
  </div>
</template>

<style scoped lang="scss">
.app-chip-list {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.app-chip-list[data-expanded='false'] .app-chip-list__chip:nth-child(n + 3) {
  display: none;
}
</style>
