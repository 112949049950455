<template>
  <button type="button" class="app-button-wrapper">
    <slot />
  </button>
</template>

<style scoped lang="scss">
.app-button-wrapper {
  border: none;
  background: none;
  cursor: pointer;
  line-height: 0;
  padding: 0;
}

.app-button-wrapper:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
