import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6066111f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "app-error-message",
  "data-testid": "app-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconError = _resolveComponent("IconError")!

  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_IconError, {
          alt: "Error",
          class: "app-error-message__icon"
        }),
        _createElementVNode("span", {
          class: _normalizeClass(['app-error-message__text', `app-error-message__text-${_ctx.size}`]),
          "data-testid": "app-error-message"
        }, _toDisplayString(_ctx.error), 3)
      ]))
    : _createCommentVNode("", true)
}