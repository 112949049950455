<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import useProfileStore from '@/store/profile/useProfileStore';
import AppInput from '@/components/app/AppInput/AppInput.vue';
import { AppButton } from '@/plugins/commons';
import IconVaibeCoin from '@icons/icon-vaibe-coin.vue';
import IconExchangeArrows from '@/assets/icons/icon-exchange-arrows.vue';
import { useI18n } from 'vue-i18n';
import { GRAY_700 } from '@/shared/constants/colors';
import {
  calculateCoinValue,
  calculateCurrencyValue,
} from '@/shared/helpers/moneyCalculations/moneyCalculations';
import AppLabel from '../AppLabel/AppLabel.vue';

const profileStore = useProfileStore();

const { t } = useI18n();
const inputElement = ref();

const leftInput = defineModel<string>('amount', {
  required: true,
});

const coinsToCurrency = defineModel<boolean>('coinsToCurrency', {
  required: true,
});

const maxCoinAmount = 999999999;

interface Props {
  error: string;
}

interface State {
  rightInput: string | undefined;
}

const state = reactive<State>({
  rightInput: '0',
});

const props = defineProps<Props>();

function swapCurrencies(): void {
  coinsToCurrency.value = !coinsToCurrency.value;
  const right = state.rightInput;
  const left = leftInput.value;
  leftInput.value = right || '0';
  state.rightInput = left;
}

const leftInputLabel = computed<string>(() => {
  return coinsToCurrency.value
    ? t('games.challengeWizard.steps.prize.coinsAmount')
    : t('games.challengeWizard.steps.prize.currencyInvestment', [
        t(
          `games.prizeDistribution.prize.money.${profileStore.userProfile?.userConfig.currencyCode}`,
        ),
      ]);
});

const rightInputLabel = computed<string>(() => {
  return coinsToCurrency.value
    ? t('games.challengeWizard.steps.prize.currencyInvestment', [
        t(
          `games.prizeDistribution.prize.money.${profileStore.userProfile?.userConfig.currencyCode}`,
        ),
      ])
    : t('games.challengeWizard.steps.prize.coinsAmount');
});

function selectText() {
  inputElement.value.$el.querySelector('input').select();
}

watch(
  () => leftInput.value,
  (newValue: string, oldValue?: string) => {
    if (coinsToCurrency.value) {
      if (!Number.isInteger(Number(newValue)) && oldValue) {
        leftInput.value = oldValue;
        return;
      }

      if (newValue.toString() === '') {
        state.rightInput = undefined;
        return;
      }

      state.rightInput = String(
        calculateCurrencyValue(
          Number(newValue),
          profileStore.userProfile?.userConfig.exchangeRate,
        ),
      );
    } else {
      const coinsAmount = calculateCoinValue(
        Number(newValue),
        profileStore.userProfile?.userConfig.exchangeRate,
      );

      if (!Number.isInteger(coinsAmount) && !!oldValue) {
        leftInput.value = oldValue;
        return;
      }

      if (newValue.toString() === '') {
        state.rightInput = undefined;
        return;
      }

      state.rightInput =
        coinsAmount >= maxCoinAmount ? newValue : String(coinsAmount);

      leftInput.value = coinsAmount >= maxCoinAmount ? oldValue : newValue;
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="app-currency-converter">
    <div>
      <AppLabel :label="leftInputLabel" />
      <AppInput
        ref="inputElement"
        v-model="leftInput"
        class="app-currency-converter__input"
        type="number"
        :maxlength="9"
        :placeholder="
          t(
            'games.challengeWizard.steps.participantsGoal.goal.dropdown.placeholder.amount',
          )
        "
        width="200px"
        :error="props.error"
        @focus="selectText"
      >
        <template #append>
          <IconVaibeCoin
            v-if="coinsToCurrency"
            class="app-currency-converter__coin"
          />
          <span v-else class="app-currency-converter__input__append">
            {{ profileStore.userProfile?.userConfig.currencyCode }}
          </span>
        </template>
      </AppInput>
    </div>
    <AppButton
      class="app-currency-converter__exchange-button"
      type="secondary"
      is-button-icon
      :ripple="false"
      @click="swapCurrencies"
    >
      <IconExchangeArrows height="18px" width="18px" :color="GRAY_700" />
    </AppButton>
    <div>
      <AppLabel :label="rightInputLabel" />
      <AppInput
        v-model="state.rightInput"
        type="number"
        class="app-currency-converter__input"
        disable
        width="200px"
      >
        <template #append>
          <span
            v-if="coinsToCurrency"
            class="app-currency-converter__input__append"
          >
            {{ profileStore.userProfile?.userConfig.currencyCode }}
          </span>
          <IconVaibeCoin v-else class="app-currency-converter__coin" />
        </template>
      </AppInput>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-currency-converter {
  display: flex;
  align-items: center;
  gap: 8px;
}

.app-currency-converter__input {
  flex: 1;
}

.app-currency-converter__input__append {
  color: $gray-500;
  font-size: 14px;
  line-height: 150%;
}
.app-currency-converter__input :deep(.q-field__append) {
  padding-left: 0;
}

.app-currency-converter__coin {
  margin-left: 8px;
}
</style>
