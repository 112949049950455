<script lang="ts" setup>
import IconPasswordEyeClosed from '@/assets/icons/icon-password-eye-closed.vue';
import IconPasswordEye from '@/assets/icons/icon-password-eye.vue';
import { computed } from 'vue';
import AppButtonWrapper from '../AppButtonWrapper/AppButtonWrapper.vue';

interface Props {
  passwordHasErrors?: boolean;
  showPassword: boolean;
}
const props = defineProps<Props>();

const showPasswordIcon = computed(() => {
  return props.showPassword ? IconPasswordEyeClosed : IconPasswordEye;
});
</script>

<template>
  <AppButtonWrapper
    :class="['password-icon', { 'password-icon--error': passwordHasErrors }]"
  >
    <component :is="showPasswordIcon" />
  </AppButtonWrapper>
</template>

<style scoped lang="scss">
.password-icon {
  width: 24px;
  > :deep(.q-focus-helper) {
    display: none;
  }

  &--error {
    color: $red-500;

    & :deep(path) {
      fill: $red-500;
    }
  }
}
</style>
