import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toggle = _resolveComponent("q-toggle")!

  return (_openBlock(), _createBlock(_component_q_toggle, _mergeProps(_ctx.$props, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    class: "app-toggle"
  }), null, 16, ["modelValue"]))
}