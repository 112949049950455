import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58a86848"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "app-nuvo-container",
  "data-testid": "app-nuvo-container"
}
const _hoisted_2 = {
  class: "app-nuvo-container__header",
  "data-testid": "app-nuvo-container-header"
}
const _hoisted_3 = {
  class: "app-nuvo-container__body",
  "data-testid": "app-nuvo-container-body"
}
const _hoisted_4 = {
  class: "app-nuvo-container__body__content",
  "data-testid": "app-nuvo-container-body-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppInnerLoading = _resolveComponent("AppInnerLoading")!
  const _component_NuvoImporter = _resolveComponent("NuvoImporter")!
  const _component_AppModalDiscard = _resolveComponent("AppModalDiscard")!

  return (_ctx.language)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "app-nuvo-overlay",
        "data-testid": "app-nuvo-overlay",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleConfirmChangesModal && _ctx.toggleConfirmChangesModal(...args))),
        onKeydown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleConfirmChangesModal && _ctx.toggleConfirmChangesModal(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AppIcon, {
              name: _ctx.roundClose,
              size: "20px",
              class: "app-nuvo-container__header__icon",
              "data-testid": "app-nuvo-container-header-icon",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
            }, null, 8, ["name"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AppInnerLoading, {
              class: "app-nuvo-container__body__loader",
              size: "50px",
              showing: _ctx.isLoading
            }, null, 8, ["showing"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_NuvoImporter, {
                "license-key": _ctx.licenseKey,
                settings: _ctx.settings,
                "column-hooks": _ctx.columnHooks,
                "on-entry-init": _ctx.onEntryInit,
                "on-entry-change": _ctx.onEntryChange,
                "data-handler": _ctx.dataHandler,
                "on-results": _ctx.onResults,
                text: _ctx.$t('txt_select_files'),
                "data-testid": "app-nuvo-importer"
              }, null, 8, ["license-key", "settings", "column-hooks", "on-entry-init", "on-entry-change", "data-handler", "on-results", "text"])
            ])
          ]),
          _createVNode(_component_AppModalDiscard, {
            modelValue: _ctx.showConfirmChangesModal,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showConfirmChangesModal) = $event)),
            onDiscard: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
          }, null, 8, ["modelValue"])
        ])
      ], 32))
    : _createCommentVNode("", true)
}