<template>
  <div ref="appTimePicker">
    <AppInput
      v-model="inputValue"
      :width="width"
      :placeholder="placeholder || $t('common.hours.placeholder')"
      :error="error"
      :mask="maskTime"
      fill-mask
    >
      <template #[alignIcon]>
        <AppIcon :name="matAccessTime" />
      </template>
    </AppInput>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import AppInput from '@/components/app/AppInput/AppInput.vue';
import { matAccessTime } from '@quasar/extras/material-icons';
import { AlignDateIcon } from '@/shared/types/components';
import { Locale } from '@/shared/types/generic';
import {
  formatDateToString,
  parseDateWithTimezone,
} from '@/shared/helpers/formatDates/formatDates';
import { mapStores } from 'pinia';
import useProfileStore from '@/store/profile/useProfileStore';

export default defineComponent({
  name: 'AppTimePicker',

  components: { AppInput, AppIcon },

  props: {
    modelValue: {
      type: Date,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    width: {
      type: String,
      default: undefined,
    },
    alignIcon: {
      type: String as PropType<AlignDateIcon>,
      default: 'append',
    },
    error: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue', 'update:error'],

  data() {
    return {
      matAccessTime,
      inputValue: '',
      mask: this.$t('common.format.input.maskTime'),
      maskEdit: this.$t('common.format.input.maskTimeEdit'),
      maskTime: this.$t('common.format.input.maskTimeQuasar'),
      timeRegex: /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
      timeRegexMeridian: /^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM)$/,
    };
  },

  computed: {
    ...mapStores(useProfileStore),
    isPTorDEorESLocale(): boolean {
      return (
        this.$i18n.locale === Locale.PT ||
        this.$i18n.locale === Locale.DE ||
        this.$i18n.locale === Locale.ES
      );
    },

    inputMask(): RegExp {
      return this.isPTorDEorESLocale ? this.timeRegex : this.timeRegexMeridian;
    },
  },

  watch: {
    modelValue: {
      handler(newValue: Date): void {
        this.inputValue = formatDateToString(
          newValue,
          this.mask,
          this.profileStore?.userProfileSite?.site.timezone.ianaTimezone || '',
        );
      },
      immediate: true,
    },

    inputValue: {
      async handler(newValue: string): Promise<void> {
        this.validateTime(newValue);
        await this.$nextTick();
        if (!this.error) {
          const newDate = parseDateWithTimezone(
            newValue,
            this.mask,
            this.profileStore?.userProfileSite?.site.timezone.ianaTimezone ||
              '',
            this.modelValue,
          );
          if (
            new Date(newDate).getTime() !==
            new Date(this.modelValue || new Date()).getTime()
          ) {
            this.$emit('update:modelValue', newDate);
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    validateTime(value: string): void {
      this.$emit(
        'update:error',
        !this.inputMask.test(value.trim())
          ? this.$t('errors.date.invalidTime')
          : '',
      );
    },
  },
});
</script>
