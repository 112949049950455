<script setup lang="ts">
import AppSeparator from '@/components/app/AppSeparator/AppSeparator.vue';
import IconClear from '@/assets/icons/icon-clear.vue';
import { computed, useSlots } from 'vue';
import { useI18n } from 'vue-i18n';
import { SidebarTitle } from '@/shared/types/generic';
import { GRAY_600 } from '@/shared/constants/colors';
import { AppButton } from '@/plugins/commons';
import AppTooltip from '../AppTooltip/AppTooltip.vue';

const { t } = useI18n();

interface Props {
  itemType: SidebarTitle;
  itemTitle?: string;
  viewMode: boolean;
  itemUrl?: string;
  itemId?: string;
  hideHeaderContent?: boolean;
}

interface Emits {
  (e: 'close-sidebar'): void;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  itemUrl: '',
  itemId: '',
  hideHeaderContent: false,
  itemTitle: '',
});

function handleClose(): void {
  emit('close-sidebar');
}

const title = computed<string>(() => {
  return props.viewMode
    ? t(`common.sidebar.title.${props.itemType}`)
    : t(`common.sidebar.editTitle.${props.itemType}`);
});

const id = computed<string>(() => {
  return props.itemType === SidebarTitle.METRIC
    ? t('common.sidebar.tid', [props.itemId])
    : t('common.sidebar.id', [props.itemId]);
});

const slots = useSlots();
</script>

<template>
  <div class="header">
    <div class="header__category">
      <b class="header__category-title">{{ title }}</b>
      <div v-if="viewMode" class="header__category-actions">
        <slot name="actions" />
        <AppSeparator v-if="!!slots.actions" vertical inset />
        <AppButton type="tertiary" size="S" is-button-icon @click="handleClose">
          <IconClear width="16" height="16" :color="GRAY_600" />
          <AppTooltip anchor="top middle" self="bottom middle" :offset="[5, 5]">
            {{ t('common.close') }}
          </AppTooltip>
        </AppButton>
      </div>
    </div>
    <template v-if="!hideHeaderContent && viewMode">
      <div class="header__item">
        <img
          v-if="itemUrl"
          :class="[
            'header__item-illustration',
            {
              'header__item-illustration--round':
                itemType === SidebarTitle.USER,
            },
          ]"
          :src="props.itemUrl"
          alt="item cover image"
        />
        <div class="header__item-details">
          <span v-if="itemId" class="header__item-details--id">
            {{ id }}
          </span>
          <b class="header__item-details--name"> {{ itemTitle }}</b>
          <slot name="details" />
        </div>
      </div>
      <AppSeparator />
    </template>
  </div>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header__category {
  display: flex;
  flex-direction: row;
  color: $gray-500;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.header__item {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.header__item-details {
  display: flex;
  flex-direction: column;
  color: $gray-800;
  font-size: 20px;
  width: 100%;
  overflow: hidden;
}

.header__item-illustration {
  border-radius: 4px;
  height: 72px;
  width: 72px;
  object-fit: cover;
}

.header__item-illustration--round {
  border-radius: 8px;
}

.header__item-illustration--default {
  background-color: $gray-50;
  box-sizing: border-box;
  padding: 4px;
}

.header__category-actions {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.header__item-details--id {
  font-size: 12px;
  color: $gray-700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header__category-title {
  font-size: 18px;
}

.header__item-details--name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header__item__manager {
  display: flex;
  flex-direction: row;
  gap: 16px;
  background: $gray-50;
  padding: 16px;
  border-radius: 8px;
}

.header__item-details--name__manager {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  color: $gray-800;
}

.header__item-illustration__manager {
  border-radius: 4px;
  height: 64px;
  width: 64px;
  object-fit: cover;
}
</style>
