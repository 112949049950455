<template>
  <q-avatar class="app-avatar">
    <slot />
  </q-avatar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppAvatar',

  props: {
    borderRadius: {
      type: String,
      default: '50%',
    },
  },
});
</script>

<style scoped lang="scss">
.app-avatar {
  border-radius: v-bind(borderRadius);
}
</style>
