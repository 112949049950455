import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createBlock(_component_q_icon, _mergeProps(_ctx.$props, {
    class: ['app-icon', _ctx.appIconClass],
    "data-testid": "app-icon"
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 16, ["class"]))
}