import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb3a1ce4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "app-duration",
  "data-testid": "app-duration"
}
const _hoisted_2 = {
  class: "app-duration__title",
  "data-testid": "app-duration-startDate-label"
}
const _hoisted_3 = {
  class: "app-duration__dates",
  "data-testid": "app-duration-startDate-container"
}
const _hoisted_4 = {
  class: "app-duration__title",
  "data-testid": "app-duration-endDate-label"
}
const _hoisted_5 = {
  class: "app-duration__dates",
  "data-testid": "app-duration-EndDate-container"
}
const _hoisted_6 = { class: "app-duration_warnings" }
const _hoisted_7 = { class: "app-duration-result-forecast" }
const _hoisted_8 = {
  class: "app-duration__warning",
  "data-testid": "app-duration-warning"
}
const _hoisted_9 = {
  class: "app-duration__warning-title",
  "data-testid": "app-duration-warning-title"
}
const _hoisted_10 = {
  class: "app-duration__warning",
  "data-testid": "app-duration-warning"
}
const _hoisted_11 = {
  class: "app-duration__warning-title",
  "data-testid": "app-duration-warning-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButtonToggle = _resolveComponent("AppButtonToggle")!
  const _component_AppDatePicker = _resolveComponent("AppDatePicker")!
  const _component_AppTimePicker = _resolveComponent("AppTimePicker")!
  const _component_AppResultsRelease = _resolveComponent("AppResultsRelease")!
  const _component_AppMessage = _resolveComponent("AppMessage")!
  const _component_AppSequence = _resolveComponent("AppSequence")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppButtonToggle, {
      modelValue: _ctx.model.preset,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.preset) = $event)),
      class: "app-duration__buttons",
      "data-testid": "app-duration-btn",
      options: _ctx.presetOptions,
      "isolated-buttons": ""
    }, null, 8, ["modelValue", "options"]),
    _createVNode(_component_AppSequence, null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('common.startDate')), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AppDatePicker, {
              modelValue: _ctx.model.startDate,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.startDate) = $event)),
              class: "app-duration__start-time",
              error: _ctx.errors.startDate,
              "data-testid": "app-duration-startDate"
            }, null, 8, ["modelValue", "error"]),
            _createVNode(_component_AppTimePicker, {
              modelValue: _ctx.model.startDate,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.startDate) = $event)),
              error: _ctx.modelError.startTime,
              "onUpdate:error": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelError.startTime) = $event)),
              class: "app-duration__start-time",
              "align-icon": "prepend",
              "data-testid": "app-duration-startTime"
            }, null, 8, ["modelValue", "error"])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('common.endDate')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AppDatePicker, {
              modelValue: _ctx.model.endDate,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.endDate) = $event)),
              class: "app-duration__end-time",
              disabled: _ctx.disableEndDate,
              error: _ctx.errors.endDate,
              "data-testid": "app-duration-endDate"
            }, null, 8, ["modelValue", "disabled", "error"]),
            _createVNode(_component_AppTimePicker, {
              modelValue: _ctx.model.endDate,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.endDate) = $event)),
              error: _ctx.modelError.endTime,
              "onUpdate:error": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modelError.endTime) = $event)),
              class: "app-duration__end-time",
              "align-icon": "prepend",
              "data-testid": "app-duration-endTime"
            }, null, 8, ["modelValue", "error"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (!!_ctx.resultsForecast)
                ? (_openBlock(), _createBlock(_component_AppResultsRelease, {
                    key: 0,
                    date: _ctx.resultsForecast,
                    context: _ctx.context
                  }, null, 8, ["date", "context"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.showWarning)
              ? (_openBlock(), _createBlock(_component_AppMessage, {
                  key: 0,
                  class: "warning",
                  icon: _ctx.outlinedInfo,
                  "data-testid": "warning-message"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_8, [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('common.reminder')), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.warning), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["icon"]))
              : _createCommentVNode("", true),
            (_ctx.teamsWarning)
              ? (_openBlock(), _createBlock(_component_AppMessage, {
                  key: 1,
                  class: "warning",
                  icon: _ctx.outlinedInfo,
                  "data-testid": "warning-message"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('common.reminder')), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.teamsWarning), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["icon"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    })
  ]))
}