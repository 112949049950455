import { SkeletonStyle } from '@/shared/types/generic';

const skeletons: SkeletonStyle[] = [
  {
    type: 'rect',
    style: 'width: 10%; height: 20px;',
  },
  {
    type: 'rect',
    style: 'width: 10%; height: 20px;',
  },
  {
    type: 'rect',
    style: 'width: 10%; height: 20px;',
  },
  {
    type: 'rect',
    style: 'width: 10%; height: 20px;',
  },
  {
    type: 'rect',
    style: 'width: 10%; height: 20px;',
  },
  {
    type: 'rect',
    style: 'width: 10%; height: 20px;',
  },
  {
    type: 'rect',
    style: 'width: 10%; height: 20px;',
  },
];

export default skeletons;
