<template>
  <div class="app-breadcrumbs" data-testid="app-breadcrumbs">
    <template v-for="(route, index) in matchedRoutes" :key="index">
      <template v-if="!isLastRoute(route.name)">
        <router-link :to="route.path" class="app-breadcrumbs__link">
          {{ $t(`nav.${String(route?.name)}`) }}
        </router-link>
        <AppIcon
          class="app-breadcrumbs__separator"
          name="chevron_right"
          size="18px"
        />
      </template>
      <h1 v-else>
        {{ $t(`nav.${String(route?.name)}`) }}
      </h1>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import { RouteLocationMatched, RouteRecordName } from 'vue-router';

export default defineComponent({
  name: 'AppBreadcrumbs',

  components: {
    AppIcon,
  },

  computed: {
    matchedRoutes(): RouteLocationMatched[] {
      return this.$route.matched.filter(
        (match) => !match.meta.firstLevel && match.name,
      );
    },
  },

  methods: {
    isLastRoute(route: RouteRecordName | undefined): boolean {
      return route === this.matchedRoutes[this.matchedRoutes.length - 1].name;
    },
  },
});
</script>

<style scoped lang="scss">
.app-breadcrumbs {
  display: flex;
  align-self: stretch;
  align-items: center;
}

.app-breadcrumbs__link {
  font-size: 24px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.02em;
  min-height: auto;
  padding: 0;
  color: $primary-color;
  text-decoration: none;
}

.app-breadcrumbs__link:hover {
  text-decoration: underline;
}

.app-breadcrumbs__separator {
  align-self: center;
  color: $gray-800;
  width: 32px;
  height: 32px;
  display: flex;
}
</style>
