<template>
  <AppTooltip
    ref="scheduledTooltip"
    class="app-schedule-tooltip__wrapper"
    :anchor="anchorPosition"
    :self="selfPosition"
    max-width="336px"
    @before-show="handleShowPopup"
    @before-hide="handleHidePopup"
  >
    <DashboardTooltipContent :challenge="game" />
  </AppTooltip>
</template>

<script lang="ts">
import { ChallengeTypeStaticReference } from '@/shared/types/challenges';
import { IChallenge } from '@/shared/types/wizard';
import { DatePeriod } from '@/shared/types/generic';
import { defineComponent, PropType } from 'vue';
import { mapStores } from 'pinia';
import { ResponseError } from '@/shared/types/utils';
import notify from '@/shared/helpers/notify/notify';
import useDashboardStore from '@/store/dashboard/useDashboardStore';
import useSharedStore from '@/store/shared/useSharedStore';
import AppTooltip from '@/components/app/AppTooltip/AppTooltip.vue';
import useTokenStore from '@/store/token/useTokenStore';
import usePostHogEvents from '@/composables/usePostHog/usePostHogEvents';
import { formatTime } from '@/shared/helpers/formatDates/formatDates';
import useProfileStore from '@/store/profile/useProfileStore';
import { PostHogEvents } from '@/shared/types/posthog';
import { roundArrowForward } from '@quasar/extras/material-icons-round';
import useFeatures from '@/composables/useFeatures/useFeatures';
import DashboardTooltipContent from '@/components/dashboard/DashboardTooltipContent/DashboardTooltipContent.vue';
import { AxiosError } from 'axios';
import { BLUE_50 } from '@/shared/constants/colors';
import useChallengesStore from '@/store/challenges/useChallengesStore';

export default defineComponent({
  name: 'AppScheduleTooltip',

  components: {
    AppTooltip,
    DashboardTooltipContent,
  },

  props: {
    game: {
      type: Object as PropType<IChallenge>,
      required: true,
    },
  },

  setup() {
    const { isFeatureEnabled } = useFeatures();
    return { isFeatureEnabled };
  },

  data() {
    return {
      DatePeriod,
      selectedDatePeriod: DatePeriod.WEEK,
      isLoading: false,
      timeoutId: undefined as number | undefined,
      eventDelay: 3000,
      ChallengeTypeStaticReference,
      roundArrowForward,
      BLUE_50,
    };
  },

  computed: {
    ...mapStores(
      useDashboardStore,
      useSharedStore,
      useTokenStore,
      useProfileStore,
      useChallengesStore,
    ),

    metricCategoryLabel(): string {
      return this.game.metric?.name
        ? this.$t('games.metric')
        : this.$t('metrics.category');
    },

    metricCategoryValue(): string {
      return this.game.metric?.name || this.game.category?.name || '';
    },

    goalDescription(): string {
      return this.challengesStore.getGoalTranslation(this.$t, {
        typeStaticReference: this.game.type.staticReference,
        goalStaticReference: this.game.goal?.staticReference,
        unitMeasureName: this.game.unitMeasure.name,
        targetAmount: this.game.targetAmount,
      });
    },

    disclaimerIdentifier(): string {
      return this.game.hasMultipleTimezones ? '*' : '';
    },

    unitMeasureLabel(): string {
      return `1 ${this.$t(`enums.${this.game.unitMeasure.name}`, 1)}`;
    },

    isAchieverType(): boolean {
      return (
        this.game.type.staticReference === ChallengeTypeStaticReference.ACHIEVER
      );
    },

    anchorPosition(): string {
      return ['xs', 'sm'].includes(this.$q.screen.name)
        ? 'center end'
        : 'center start';
    },

    selfPosition(): string {
      return ['xs', 'sm'].includes(this.$q.screen.name)
        ? 'center start'
        : 'center end';
    },
  },

  methods: {
    notify,

    handleShowPopup(): void {
      this.timeoutId = setTimeout(() => {
        usePostHogEvents().postHogEvent(PostHogEvents.DSH_TOOLTIP_HOVER);
      }, this.eventDelay);
      this.loadGameDetails();
    },

    handleHidePopup(): void {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = undefined;
      }
    },

    async loadGameDetails(): Promise<void> {
      if (this.game.reward === undefined) {
        await this.$nextTick();
        const tooltips = document.querySelectorAll('[id^=q-portal--tooltip--]');
        try {
          this.sharedStore.setLoader(
            true,
            `#${tooltips[tooltips.length - 1].id} .app-tooltip`,
          );

          await this.dashboardStore.loadChallengeDetails(this.game.reference);
        } catch (error) {
          if (error instanceof ResponseError) {
            this.notify(
              this.$t(`errors.reason.${error.reason.code}`),
              'danger',
            );
          } else if (
            error instanceof AxiosError &&
            error.code !== AxiosError.ERR_CANCELED
          ) {
            this.notify(this.$t('errors.general.wrong'), 'danger');
          }
        } finally {
          this.sharedStore.setLoader(
            false,
            `#${tooltips[tooltips.length - 1].id} .app-tooltip`,
          );
        }
      }
    },
    formatTime,
  },
});
</script>

<style scoped lang="scss">
.image-wrapper {
  border-radius: 8px;
  background-color: $primary-background;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-schedule-tooltip {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.app-schedule-tooltip__header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.app-schedule-tooltip__title {
  font-size: 15px;
  font-weight: 700;
  color: $gray-800;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.app-schedule-tooltip__section {
  display: flex;
  flex-direction: column;
}

.app-schedule-tooltip__section span {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.separator {
  height: 2px;
  background: $gray-50;
}

.separator__first {
  height: 4px;
  background: $gray-50;
}

.app-schedule-tooltip__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px 8px;
}

.app-schedule-tooltip__row > * {
  flex-basis: calc(50% - 4px);
}

.app-schedule-tooltip__prize {
  display: flex;
  flex-direction: row;
}

.app-schedule-tooltip__prize {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.app-schedule-tooltip__prize-coins {
  display: flex;
  align-items: center;
  gap: 4px;
  color: $gray-800;
}

.app-schedule-tooltip__separator {
  background-color: $gray-100;
}
.app-schedule-tooltip__separator--header {
  margin: 0 -16px;
  height: 4px;
  background-color: $gray-50;
}

.app-schedule-tooltip__value-with-icon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}

.app-schedule-tooltip__icon {
  height: 16px;
  margin-top: 2px;
}

.app-schedule-tooltip__disclaimer {
  color: $gray-500;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}

.app-schedule-tooltip__dates {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.app-schedule-tooltip__ratio {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.app-schedule-tooltip__section :deep(.app-badge) {
  width: fit-content;
  padding: 0 12px;
  border-radius: 16px;
  margin-top: 4px;
}
</style>
