<template>
  <div class="app-sequence" data-testid="app-sequence">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppSequence',
});
</script>

<style scoped lang="scss">
.app-sequence > :deep(*) {
  position: relative;
  display: block;
  padding-left: 24px;
  margin: 0;
}

.app-sequence > :deep(*:not(:last-child)) {
  padding-bottom: 24px;
}

.app-sequence > :deep(*::before) {
  content: '';
  position: absolute;
  left: 4px;
  height: calc(100% - 10px);
  width: 2px;
  top: 16px;
  background-image: $neutral-gradient-light;
  background-position: left;
  background-size: 2px 6px;
  background-repeat: repeat-y;
}

.app-sequence > :deep(*::after) {
  content: '';
  position: absolute;
  left: 0;
  top: 6px;
  width: 10px;
  height: 10px;
  border: 2px solid $primary-color;
  border-radius: 50px;
  background-color: $white;
}

.app-sequence > :deep(*:last-child::before) {
  content: none;
}

.app-sequence > :deep(*:last-child::after) {
  border: none;
  background-color: $primary-color;
}
</style>
