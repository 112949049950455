<script setup lang="ts">
interface Props {
  isLastStep: boolean;
}

const props = defineProps<Props>();
</script>

<template>
  <div class="app-wizard-layout">
    <div class="app-wizard-layout__container">
      <div class="app-wizard-layout__content-container">
        <div class="app-wizard-layout__header">
          <slot name="header" />
        </div>
        <div class="app-wizard-layout__body">
          <slot name="body" />
        </div>
        <div class="app-wizard-layout__footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
    <div
      :class="[
        props.isLastStep
          ? 'app-wizard-layout__sidebar--summary'
          : 'app-wizard-layout__sidebar',
      ]"
    >
      <slot name="sidebar" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-wizard-layout {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: auto;
}

.app-wizard-layout__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $gray-25;
  padding-top: 48px;
}

.app-wizard-layout__header {
  width: 100%;
}

.app-wizard-layout__body {
  width: 100%;
  flex-grow: 1;
  display: flex;
  overflow: auto;
  z-index: 1;
}

.app-wizard-layout__footer {
  width: 100%;
  padding: 40px 6%;
  background-color: $gray-25;
  position: sticky;
  bottom: 0;
  box-shadow: -6px -15px 11px -6px #f6f6f6aa;
  z-index: 5;
}

.app-wizard-layout__sidebar {
  width: 304px;
  min-width: 304px;
  height: 100%;
}

.app-wizard-layout__sidebar--summary {
  width: 352px;
  min-width: 352px;
}

@media (min-width: 1599px) {
  .app-wizard-layout__sidebar--summary {
    width: 560px;
    min-width: 560px;
  }
}

.app-wizard-layout__content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  overflow: auto;
}

.app-wizard-layout__content-container > *:not(:last-child) {
  padding: 0 6%;
}
</style>
