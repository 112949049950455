import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardTooltipContent = _resolveComponent("DashboardTooltipContent")!
  const _component_AppTooltip = _resolveComponent("AppTooltip")!

  return (_openBlock(), _createBlock(_component_AppTooltip, {
    ref: "scheduledTooltip",
    class: "app-schedule-tooltip__wrapper",
    anchor: _ctx.anchorPosition,
    self: _ctx.selfPosition,
    "max-width": "336px",
    onBeforeShow: _ctx.handleShowPopup,
    onBeforeHide: _ctx.handleHidePopup
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DashboardTooltipContent, { challenge: _ctx.game }, null, 8, ["challenge"])
    ]),
    _: 1
  }, 8, ["anchor", "self", "onBeforeShow", "onBeforeHide"]))
}