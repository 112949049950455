<script setup lang="ts">
import IconEdit from '@/assets/icons/icon-edit.vue';
import IconDelete from '@/assets/icons/icon-delete.vue';
import { AppButton } from '@/plugins/commons';

interface Props {
  editTooltip?: string;
  deleteTooltip?: string;
  isDeleteDisabled?: boolean;
  isEditDisabled?: boolean;
  alignedTop?: boolean;
}
const props = defineProps<Props>();

interface Emits {
  (e: 'edit'): void;
  (e: 'delete'): void;
}
const emit = defineEmits<Emits>();

function handleEditClick(): void {
  if (!props.isEditDisabled) {
    emit('edit');
  }
}
function handleDeleteClick(): void {
  if (!props.isDeleteDisabled) {
    emit('delete');
  }
}
</script>

<template>
  <div
    :class="['app-list-item', { 'app-list-item--top-aligned': alignedTop }]"
    data-testid="app-list-item"
  >
    <div class="app-list-item__container">
      <slot name="leading" />
      <span>
        <div class="app-list-item__title" data-testid="app-list-item-title">
          <slot name="title" />
        </div>
        <div>
          <span
            class="app-list-item__subtitle"
            data-testid="app-list-item-subtitle"
          >
            <slot name="subtitle" />
          </span>
        </div>
      </span>
    </div>
    <span
      v-if="$slots.description"
      class="app-list-item__description"
      data-testid="app-list-item-description"
    >
      <slot name="description" />
    </span>
    <span
      v-if="$slots.numberOfItens"
      class="app-list-item__count"
      data-testid="app-list-item-count"
    >
      <slot name="numberOfItens" />
    </span>
    <div class="app-list-item__actions" data-testid="app-list-item-actions">
      <slot name="actions" />
      <template v-if="!$slots.actions">
        <AppButton
          type="tertiary"
          size="S"
          is-button-icon
          :disabled="isEditDisabled"
          :tooltip-text="editTooltip"
          data-testid="app-list-item-actions-edit-button"
          @click="handleEditClick"
        >
          <IconEdit width="16px" height="16px" />
        </AppButton>
        <AppButton
          type="tertiary"
          size="S"
          is-button-icon
          :disabled="isDeleteDisabled"
          :tooltip-text="deleteTooltip"
          data-testid="app-list-item-actions-delete"
          @click="handleDeleteClick"
        >
          <IconDelete width="16px" height="16px" />
        </AppButton>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-list-item {
  gap: 5%;
  display: flex;
  background: $white;
  padding: 16px 24px;
  border-radius: 16px;
  align-items: center;
  justify-content: space-between;
}

.app-list-item--top-aligned {
  align-items: flex-start;
}

.app-list-item + .app-list-item {
  margin-top: 16px;
}

.app-list-item__container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.app-list-item__count {
  flex-shrink: 0;
}

.app-list-item__title,
.app-list-item__description {
  font-size: 15px;
  overflow: hidden;
  font-weight: 400;
  line-height: 150%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.app-list-item__header {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.app-list-item__subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $gray-500;
}

.app-list-item__title {
  font-weight: 700;
  color: $gray-800;
}

.app-list-item__description {
  width: 30%;
  flex-grow: 1;
  color: $gray-500;
  min-height: 45px;
}

.app-list-item__actions {
  gap: 16px;
  display: flex;
  align-items: center;
}
</style>
