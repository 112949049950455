<template>
  <div class="app-image-selector" data-testid="app-image-selector">
    <div
      class="app-image-selector__button__overlay"
      data-testid="app-image-selector-button-overlay"
    >
      <div
        :class="[
          'app-image-selector__button',
          { 'app-image-selector__button--disabled': disabled },
        ]"
        data-testid="app-image-selector-button"
        @click="handleImageSelectorClick"
        @keydown="handleImageSelectorClick"
      >
        <div class="game-image-selector__icon">
          <AppIcon
            v-if="selectedImage"
            class="game-image-selector__icon__element game-image-selector__icon__image"
            :name="'img:' + selectedImage"
            size="140px"
            data-testid="app-image-selector-button-image"
          />

          <AppButton
            class="game-image-selector__icon__element game-image-selector__icon__element--edit"
            type="primary"
            is-button-icon
            :disable="disabled"
            @click="handleImageSelectorClick"
          >
            <IconEdit width="16" height="16" :color="WHITE" />
          </AppButton>
        </div>
      </div>
    </div>
    <div v-if="error">
      <div v-if="errorMessage.name">
        <AppErrorMessage :error="errorMessage.name" />
      </div>
      <div v-if="errorMessage.size">
        <AppErrorMessage :error="errorMessage.size" />
      </div>
    </div>
    <AppModal
      v-model="isImageSelectorModalShown"
      width="unset"
      max-height="85%"
    >
      <template #header>
        <div
          class="app-image-selector__modal__header"
          data-testid="app-image-selector-modal-header"
        >
          <span
            class="app-image-selector__modal__header__title"
            data-testid="app-image-selector-modal-header-title"
          >
            {{ title }}
          </span>
          <AppButton
            v-if="showUpload"
            type="secondary"
            data-testid="app-image-selector-button-upload"
            @click="handleUploadImage"
          >
            {{ $t('store.uploadImage') }}
          </AppButton>
        </div>
      </template>
      <template #body>
        <div class="app-image-selector__modal__body">
          <div
            v-for="image in options"
            :key="image.reference"
            class="app-image-selector__modal__body__image__container"
            data-testid="app-image-selector-modal-body"
            @click="handleImageClick(image)"
            @keydown="handleImageClick(image)"
          >
            <img
              :class="[
                'app-image-selector__modal__body__image',
                {
                  'app-image-selector__modal__body__image--selected':
                    selectedImageName === image.name,
                },
              ]"
              :src="image.url"
              :alt="image.name"
              :draggable="false"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <AppButton
          type="tertiary"
          data-testid="cancel-modal"
          :label="$t('labels.common.cancel')"
          @click="handleCancelClick"
        />
        <AppButton
          type="primary"
          data-testid="save-modal"
          :label="$t('labels.common.save')"
          @click="handleSaveClick"
        />
      </template>
    </AppModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import AppModal from '@/components/app/AppModal/AppModal.vue';
import { AppButton } from '@/plugins/commons';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import { Image } from '@/shared/types/generic';
import { WHITE } from '@/shared/constants/colors';
import IconEdit from '@/assets/icons/icon-edit.vue';
import AppErrorMessage from '../AppErrorMessage/AppErrorMessage.vue';

export default defineComponent({
  name: 'AppImageSelector',

  components: {
    AppIcon,
    AppButton,
    AppModal,
    AppErrorMessage,
    IconEdit,
  },

  props: {
    modelValue: {
      type: String,
      default: undefined,
    },

    error: {
      type: String,
      default: undefined,
    },

    file: {
      type: File,
      default: undefined,
    },

    imageUrl: {
      type: String,
      default: undefined,
    },

    options: {
      type: Array as PropType<Image[]>,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    showUpload: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'update:file'],

  data() {
    return {
      selectedImageName: undefined as string | undefined,
      isImageSelectorModalShown: false,
      selectedImage: undefined as string | ArrayBuffer | null | undefined,
      WHITE,
    };
  },

  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(newValue: unknown) {
        this.$emit('update:modelValue', newValue);
      },
    },
    iconClass() {
      if (this.error) {
        return 'app-image-selector__button__image app-image-selector__button__image--error';
      }

      return 'app-image-selector__button__image';
    },
    errorMessage() {
      return {
        name: this.error?.split('\n')[0],
        size: this.error?.split('\n')[1],
      };
    },
  },

  watch: {
    options: {
      handler() {
        if (this.options.length > 0 && !this.imageUrl) {
          this.selectedImage = this.getDefaultImage();
        }
      },
      immediate: true,
    },
    imageUrl: {
      handler() {
        if (this.imageUrl) {
          this.selectedImage = this.imageUrl;
        }
      },
      immediate: true,
    },
  },

  mounted() {
    if (this.imageUrl) {
      this.selectedImage = this.imageUrl;
    }
  },

  methods: {
    handleCancelClick() {
      this.isImageSelectorModalShown = false;
    },

    handleSaveClick() {
      if (this.selectedImageName) {
        this.$emit('update:modelValue', this.selectedImageName);

        this.selectedImage = this.options.find(
          (image) => image.name === this.selectedImageName,
        )?.url;

        this.$emit('update:file', undefined);

        this.isImageSelectorModalShown = false;
      }
    },

    handleImageSelectorClick() {
      if (!this.disabled) {
        if (this.modelValue !== this.selectedImageName) {
          this.selectedImageName = this.modelValue;
        }

        this.isImageSelectorModalShown = true;
      }
    },

    handleImageClick(image: Image) {
      this.selectedImageName = image.name;
    },

    handleUploadImage() {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', '.png, .jpg');

      input.click();

      input.addEventListener('change', (event) => {
        const target = event.target as HTMLInputElement;
        if (target.files) {
          const uploadedImage = target.files[0];

          const reader = new FileReader();

          reader.readAsDataURL(uploadedImage);

          reader.onload = (e) => {
            this.selectedImage = e.target?.result;

            this.selectedImageName = undefined;
            this.$emit('update:modelValue', this.selectedImageName);

            this.$emit('update:file', uploadedImage);
            this.isImageSelectorModalShown = false;
          };
        }
      });
    },

    getDefaultImage() {
      return this.options.find((item) => !!item.default)?.url;
    },
  },
});
</script>

<style scoped lang="scss">
.app-image-selector {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $white;
}

.app-image-selector .app-imageSelec {
  transform: translateY(0);
}

.app-image-selector__modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-image-selector__modal__header__title {
  line-height: 130%;
  font-weight: 700;
  font-size: 28px;
  color: $gray-800;
}

.app-image-selector__modal__body {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: '. . . . .';
  grid-template-rows: auto;
  grid-auto-flow: row;
  gap: 32px 32px;
  display: grid;
  width: 828px;
  min-height: 490px;
  overflow-y: auto;
}

.app-image-selector__button__overlay {
  display: flex;
  justify-content: center;
  background-color: $white;
  z-index: 5;
  width: 100%;
}

.app-image-selector__button {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  display: flex;
  height: 140px;
  width: 140px;
  gap: 8px;
  z-index: 1;
}

.app-image-selector__button__label {
  box-sizing: border-box;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  font-weight: 700;
  padding: 6px 0;
  color: $gray-800;
  bottom: 10px;
  right: 10px;
  left: 10px;
}

.app-image-selector__modal__body__image {
  width: 140px;
  transition: all 0.2s ease-in-out;
  border: 0px solid $primary-color;
}

.app-image-selector__button__image {
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10.2096px rgba(0, 0, 0, 0.1);
}

.app-image-selector__button__image--error {
  border-color: $red-500;
}

.app-image-selector__modal__body__image,
.app-image-selector__button__image :deep(img),
.app-image-selector__modal__body__image :deep(img) {
  border-radius: 8px;
}

.app-image-selector__modal__body__image--selected {
  border-width: 4px;
  background-color: color-mix(in srgb, var(--primary-color), transparent 80%);
}

.app-image-selector__modal__body__image__container {
  position: relative;
  cursor: pointer;
}

.game-image-selector__icon {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  display: flex;
  height: 140px;
  width: 140px;
  gap: 8px;
}

.game-image-selector__icon__element {
  position: absolute;
}
.game-image-selector__icon__element--edit {
  z-index: 2;
  bottom: 8px;
  left: 8px;
  width: 40px;
  height: 40px;
}

.game-image-selector__icon__image {
  color: $gray-200;
}

.game-image-selector__icon__image :deep(img) {
  border: 1px solid $gray-400;
  border-radius: 8px;
}

.game-image-selector__modal__body__image,
.game-image-selector__modal__body__image :deep(img) {
  border-radius: 8px;
}

.game-image-selector__icon__element--edit {
  z-index: 2;
  bottom: 8px;
  left: 8px;
  width: 40px;
  height: 40px;
}

.app-image-selector__button__overlay {
  justify-content: flex-start;
}

.game-image-selector__icon__element--disabled {
  opacity: 0.5;
}

.app-image-selector__button--disabled {
  cursor: not-allowed;
}
</style>
