<script setup lang="ts">
import { BaseTooltip } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import AppLabel from '@/components/app/AppLabel/AppLabel.vue';

interface Props {
  isDisabled?: boolean;
  label: string;
  tooltipMessage?: string;
}

interface Emits {
  (e: 'click'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const handleClickEvent = (): void => {
  if (!props.isDisabled) {
    emit('click');
  }
};
</script>

<template>
  <div class="app-dropdown-label">
    <BaseTooltip v-if="isDisabled && tooltipMessage">
      {{ tooltipMessage }}
    </BaseTooltip>
    <AppLabel
      :class="[
        'app-button-dropdown-option',
        {
          'app-button-dropdown-option--disabled': isDisabled,
        },
      ]"
      margin-bottom="0"
      :disabled="isDisabled"
      :label="label"
      @click="handleClickEvent"
    />
  </div>
</template>

<style lang="scss" scoped>
.app-button-dropdown-option :deep(.app-label__label) {
  padding: 6px 8px;
  font-weight: 400;
  line-height: 21px;
  font-size: 14px;
  min-width: 152px;
  cursor: pointer;
  margin: 0;
  width: 100%;
  border-radius: 4px;
}

.app-button-dropdown-option--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.app-button-dropdown-option:not(.app-button-dropdown-option--disabled)
  :deep(.app-label__label):hover {
  background-color: $gray-50;
}
</style>
