import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "appTimePicker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppInput = _resolveComponent("AppInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppInput, {
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      width: _ctx.width,
      placeholder: _ctx.placeholder || _ctx.$t('common.hours.placeholder'),
      error: _ctx.error,
      mask: _ctx.maskTime,
      "fill-mask": ""
    }, {
      [_ctx.alignIcon]: _withCtx(() => [
        _createVNode(_component_AppIcon, { name: _ctx.matAccessTime }, null, 8, ["name"])
      ]),
      _: 2
    }, 1032, ["modelValue", "width", "placeholder", "error", "mask"])
  ], 512))
}