<script setup lang="ts">
import { AppButton, BaseButtonSize, BaseButtonType } from '@/plugins/commons';
import { BaseTooltip } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import IconArrowDown from '@/assets/icons/icon-arrow-down.vue';
import AppDropdownMenu from '../AppDropdownMenu/AppDropdownMenu.vue';

interface Props {
  type?: BaseButtonType;
  size?: BaseButtonSize;
  buttonIcon?: boolean;
  isButtonIcon?: boolean;
  tooltipText?: string;
  disable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'primary',
  size: 'M',
  tooltipText: '',
});
</script>
<template>
  <div>
    <AppButton
      :type="type"
      :size="size"
      :button-icon="buttonIcon"
      :is-button-icon="isButtonIcon"
      :disable="disable"
    >
      <slot v-if="$slots.default" />
      <IconArrowDown v-else />
    </AppButton>
    <AppDropdownMenu :offset="[10, 10]" class="app-button-dropdown__menu">
      <slot name="menu" />
    </AppDropdownMenu>
    <BaseTooltip v-if="props.tooltipText">
      {{ props.tooltipText }}
    </BaseTooltip>
  </div>
</template>

<style scoped lang="scss">
.app-button-dropdown {
  background-color: $primary-color;
  color: $white;
}

.app-button-dropdown:hover {
  filter: brightness(120%);
}

.app-button-dropdown > :deep(.q-btn):first-child {
  padding: 6px 16px;
  justify-content: center;
  min-height: unset;
}

.app-button-dropdown > :deep(.q-btn):nth-child(2) {
  padding: 0 8px 0 0;
  justify-content: center;
  min-height: unset;
}

.app-button-dropdown > :deep(.q-btn):first-child .q-btn__content {
  gap: 8px;
  padding-left: 8px;
  flex: 0;
}

.app-button-dropdown > :deep(.q-btn):nth-child(2) .q-btn__content {
  flex: 0;
}

.app-button-dropdown
  > :deep(.q-btn):first-child
  .q-btn__content
  > *:nth-child(2) {
  line-height: 21px;
  font-size: 14px;
  font-weight: 700;
}

.app-button-dropdown > :deep(.q-btn):first-child .q-btn__content .q-icon {
  margin: 0;
  height: 14px;
  width: 14px;
  display: flex;
}

.app-button-dropdown > :deep(.q-btn):nth-child(2) .q-btn__content .q-icon {
  font-size: 18px;
  padding: 7px;
}

.app-button-dropdown :deep(.q-btn-dropdown__arrow:empty) {
  display: none;
}

.app-button-dropdown--not-rounded {
  border-radius: 8px;
}

.app-button-dropdown--no-bg {
  background: transparent;
}

.app-button-dropdown--no-bg :deep(.q-focus-helper) {
  display: none;
}

.app-button-dropdown--add {
  padding: 9px;
  color: $white;
  border-radius: 100px;
  background: $vaibe-gradient-dark;
  box-shadow: $neutral-dark-shadow;
  font-weight: 700;
}

.app-button-dropdown--add :deep(.q-focus-helper) {
  display: none;
}

.app-button-dropdown--add > :deep(.q-btn):first-child:focus-visible {
  background: $vaibe-gradient-dark;
  border: 1px solid $blue-300;
}

.app-button-dropdown--add > :deep(.q-btn):nth-child(2):focus-visible {
  background: $vaibe-gradient-dark;
  border: 1px solid $blue-300;
}

.app-button-dropdown--add :deep(.q-icon) {
  height: 24px;
  width: 24px;
  display: flex;
  margin: 0;
}

.app-button-dropdown--transparent {
  display: flex;
  align-items: center;
  background-color: transparent;
  height: 32px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: $gray-800;
}

.app-button-dropdown--transparent:hover {
  background: transparent;
}

.app-button-dropdown--transparent :deep(.q-icon) {
  font-size: 16px;
  margin-right: 8px;
}

.app-button-dropdown__label {
  display: flex;
  gap: 8px;
}
</style>

<style lang="scss">
.app-button-dropdown__menu {
  padding: 8px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: $neutral-mid-shadow;
}
</style>
