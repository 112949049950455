<template>
  <div
    v-if="language"
    class="app-nuvo-overlay"
    data-testid="app-nuvo-overlay"
    @click="toggleConfirmChangesModal"
    @keydown="toggleConfirmChangesModal"
  >
    <div class="app-nuvo-container" data-testid="app-nuvo-container">
      <div
        class="app-nuvo-container__header"
        data-testid="app-nuvo-container-header"
      >
        <AppIcon
          :name="roundClose"
          size="20px"
          class="app-nuvo-container__header__icon"
          data-testid="app-nuvo-container-header-icon"
          @click="$emit('close')"
        />
      </div>
      <div
        class="app-nuvo-container__body"
        data-testid="app-nuvo-container-body"
      >
        <AppInnerLoading
          class="app-nuvo-container__body__loader"
          size="50px"
          :showing="isLoading"
        />
        <div
          class="app-nuvo-container__body__content"
          data-testid="app-nuvo-container-body-content"
        >
          <NuvoImporter
            :license-key="licenseKey"
            :settings="settings"
            :column-hooks="columnHooks"
            :on-entry-init="onEntryInit"
            :on-entry-change="onEntryChange"
            :data-handler="dataHandler"
            :on-results="onResults"
            :text="$t('txt_select_files')"
            data-testid="app-nuvo-importer"
          />
        </div>
      </div>

      <AppModalDiscard
        v-model="showConfirmChangesModal"
        @discard="$emit('close')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  NuvoImporter,
  ColumnHooks,
  OnEntryInit,
  OnEntryChange,
  OnResults,
  DataHandlerHeaderStep,
  ColumnAPI,
} from 'nuvo-vuejs';
import { roundClose } from '@quasar/extras/material-icons-round';
import { defineComponent, PropType } from 'vue';
import i18n from '@/plugins/i18n/i18n';
import enUS from '@/locales/en_US.json';
import AppIcon from '../AppIcon/AppIcon.vue';
import AppInnerLoading from '../AppInnerLoading/AppInnerLoading.vue';
import AppModalDiscard from '../AppModalDiscard/AppModalDiscard.vue';

export default defineComponent({
  name: 'AppNuvoContainer',

  components: { NuvoImporter, AppIcon, AppInnerLoading, AppModalDiscard },

  props: {
    columnHooks: { type: Object as PropType<ColumnHooks>, default: Function },
    onEntryInit: { type: Function as PropType<OnEntryInit>, default: Function },
    onEntryChange: {
      type: Function as PropType<OnEntryChange>,
      default: Function,
    },
    onResults: { type: Function as PropType<OnResults>, default: Function },
    headerStep: {
      type: Function as PropType<DataHandlerHeaderStep>,
      default: Function,
    },
    columns: { type: Array as PropType<ColumnAPI[]>, required: true },
    language: { type: String, required: true },
  },

  emits: ['close', 'columns'],

  setup(props) {
    type MessageSchema = typeof enUS;

    const messages: MessageSchema = JSON.parse(
      JSON.stringify(
        i18n.global.getLocaleMessage(
          JSON.parse(JSON.stringify(props.language)),
        ),
      ),
    );

    const i18nOverrides = messages;

    const settings = {
      developerMode: process.env.VUE_APP_NUVO_DEV === 'enabled',
      modal: false,
      maxEntries: 50000,
      identifier: 'product_data',
      i18nOverrides,
      columns: JSON.parse(JSON.stringify(props.columns)),
      style: {
        globals: {
          fontFamily: 'Nunito, sans-serif',
          primaryTextColor: '#262523',
        },
        header: {
          title: {
            fontSize: '24px',
            color: '#262523',
            fontWeight: 'normal',
            letterSpacing: '0.02em',
            margin: 0,
          },
          description: {
            color: '#8f887e',
          },
        },
        buttons: {
          primary: {
            height: '32px',
            padding: '6px 38px',
            backgroundColor: '#0060ff',
            borderRadius: '20px',
            color: '#fff',
            fontWeight: 700,
          },
          secondary: {
            borderRadius: '20px',
            height: '32px',
            padding: '6px 38px',
            fontWeight: 700,
          },
          tertiary: {
            borderRadius: '20px',
            height: '32px',
            padding: '6px 38px',
            fontWeight: 700,
          },
        },
        loader: {
          loadAnimationColor: '#0060ff',
        },
        dropzone: {
          icon: {
            box: {
              fill: '#0060ff',
            },
          },
        },
        sheetSelect: {
          grid: {
            checked: {
              root: {
                borderColor: '#0060ff',
              },
              checkbox: {
                backgroundColor: '#0060ff',
              },
            },
          },
        },
        headerSelect: {
          content: {
            fontSize: '12px',
          },
          sheetName: {
            title: {
              fontSize: '24px',
            },
            description: {
              color: '#8f887e',
              fontSize: '14px',
            },
            border: {
              backgroundColor: '#0060ff',
            },
          },
        },
        progressBar: {
          complete: {
            color: '#bfb8af',
            fontWeight: 'normal',
          },
          incomplete: {
            color: '#bfb8af',
            fontWeight: 'normal',
          },
          current: {
            color: '#262523',
            fontWeight: 'normal',
          },
        },
        columnMatch: {
          columnMatchHeader: {
            root: {
              backgroundColor: '#f7f9fa',
              borderColor: '#E6E2DC',
            },
            dropdown: {
              search: {},
              selectedOption: {
                backgroundColor: '#E6E2DC',
              },
              button: {
                root: {
                  color: '#262523',
                  borderColor: '#BFB8AF',
                },
                icon: {
                  color: '#262523',
                },
              },
              header: {
                backgroundColor: '#f7f9fa',
              },
              option: {
                color: '#262523',
                ':hover': {
                  backgroundColor: '#E6E2DC',
                },
              },
            },
          },
          columnMatchValue: {
            root: {
              backgroundColor: '#FFFFFF',
              borderColor: '#E6E2DC',
            },
          },
        },
        reviewEntries: {
          table: {
            td: {
              warning: {
                backgroundColor: '#fff2d7',
                color: '#262523',
              },
              error: {
                backgroundColor: '#f5d7cc',
                color: '#262523',
              },
            },
          },
        },
        dialog: {
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        popoverInfo: {
          backgroundColor: 'white',
          color: '#262523',
          fontSize: '14px',
        },
      },
    };

    return { settings };
  },

  data() {
    return {
      licenseKey: process.env.VUE_APP_NUVO_KEY || '',
      showConfirmChangesModal: false,
      isLoading: true,
      roundClose,
      dataHandler: {
        headerStep: this.$props.headerStep,
      },
    };
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 5000);
  },

  methods: {
    toggleConfirmChangesModal() {
      this.showConfirmChangesModal = !this.showConfirmChangesModal;
    },
  },
});
</script>

<style scoped lang="scss">
.app-nuvo-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000;
  background: $modal-overlay;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-nuvo-container__header__icon {
  cursor: pointer;
}

.app-nuvo-container {
  position: absolute;
  width: 80%;
  background-color: $white;
  border-radius: 16px;
  max-width: 1730px;
}

.app-nuvo-container__header {
  background-color: $white;
  padding: 10px 10px 0 0;
  display: flex;
  justify-content: flex-end;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.app-nuvo-container__body {
  position: relative;
  height: 94vh !important;
}

.app-nuvo-container__body__loader {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.app-nuvo-container__body__content {
  position: absolute;
  width: 100%;
}

.app-nuvo-container:deep(#nuvo-root-container iframe) {
  height: 94vh !important;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
</style>
