<template>
  <q-dialog v-model="model" :no-route-dismiss="noRouteDismiss">
    <q-card class="app-modal" :style="appModalStyle" data-testid="app-modal">
      <div
        v-if="showHeader"
        class="app-modal__header"
        data-testid="app-modal-header"
      >
        <slot name="header" :dismiss="dismiss" />
      </div>
      <div class="app-modal__body" data-testid="app-modal-body">
        <slot name="body" />
      </div>
      <AppSeparator v-if="showFooter" />
      <div
        v-if="showFooter"
        class="app-modal__footer"
        data-testid="app-modal-footer"
      >
        <slot name="footer" :dismiss="dismiss" />
      </div>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent, StyleValue } from 'vue';
import AppSeparator from '../AppSeparator/AppSeparator.vue';

export default defineComponent({
  name: 'AppModal',

  components: { AppSeparator },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '580px',
    },
    maxHeight: {
      type: String,
      default: undefined,
    },
    noRouteDismiss: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    model: {
      get(): boolean {
        return this.modelValue;
      },

      set(newValue: boolean) {
        this.$emit('update:modelValue', newValue);
      },
    },

    appModalStyle(): StyleValue {
      return {
        width: this.width,
        maxHeight: this.maxHeight,
      };
    },
  },

  methods: {
    dismiss() {
      this.model = false;
    },
  },
});
</script>

<style scoped lang="scss">
.app-modal {
  padding: 32px;
  background: $white;
  border: 1px solid $primary-color;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.app-modal__header,
.app-modal__body {
  color: $gray-800;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-size: 14px;
  margin-bottom: 16px;
}

.app-modal__header {
  font-weight: 700;
  font-size: 18px;
}

.app-modal__body {
  font-weight: 400;
  overflow: auto;
}

.app-modal__footer {
  gap: 24px;
  display: flex;
  margin-top: 32px;
  align-items: center;
  justify-content: flex-end;
}

.app-modal {
  max-width: unset;
}
</style>
