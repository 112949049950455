<template>
  <q-icon
    v-bind="$props"
    :class="['app-icon', appIconClass]"
    data-testid="app-icon"
  >
    <slot />
  </q-icon>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppIcon',

  props: {
    name: { type: String, default: undefined },
    size: {
      type: String,
      default: undefined,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    appIconClass() {
      return [
        {
          'app-icon--rounded': this.rounded,
          'app-icon--padding': this.padding,
        },
      ];
    },
  },
});
</script>

<style scoped lang="scss">
.app-icon--rounded {
  border-radius: 8px;
  overflow: hidden;
}

.app-icon--padding {
  padding: 4px;
}

.app-icon {
  display: inline-flex;
}
</style>
