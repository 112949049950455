<script setup lang="ts" generic="T">
import { computed, ref, watch } from 'vue';
import { AppSelectSize } from '@/shared/types/components';
import AppSeparator from '@/components/app/AppSeparator/AppSeparator.vue';
import { symRoundedKeyboardArrowDown } from '@quasar/extras/material-symbols-rounded';
import handleSelectPopupShow from '@/shared/helpers/handleSelectPopupShow/handleSelectPopupShow';
import { SelectGroup } from '@/shared/types/generic';
import AppLoading from '@/components/app/AppLoading/AppLoading.vue';
import { useI18n } from 'vue-i18n';
import AppClearIcon from '../AppClearIcon/AppClearIcon.vue';
import AppSearch from '../AppSearch/AppSearch.vue';
import AppErrorMessage from '../AppErrorMessage/AppErrorMessage.vue';

interface Props {
  modelValue: Array<string>;
  options: SelectGroup[];
  inputDebounce?: number;
  popupContentClass?: string;
  width?: string;
  showSearch?: boolean;
  size?: AppSelectSize;
  clearable?: boolean;
  placeholder?: string;
  filterLabel?: string;
  localSearch?: boolean;
  isLoadingExternalSearch?: boolean;
  error?: string;
}

interface Emits {
  (e: 'update:modelValue', newModelValue: string[]): void;
  (e: 'externalSearch', value?: string): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const { t } = useI18n();
const filter = ref('');

const model = computed<string[]>({
  get() {
    return props.modelValue;
  },

  set(newModelValue: string[]): void {
    emit('update:modelValue', newModelValue);
  },
});

const selectedItems = ref(model);

const showPlaceholder = computed<boolean>(() => {
  return props.placeholder !== undefined && model.value.length === 0;
});

const isSearchShown = computed<boolean>(() => {
  if (props.showSearch === undefined) {
    return props.options.length >= 7;
  }

  return props.showSearch;
});

const showClearable = computed<boolean>(() => {
  return props.clearable && model.value.length > 0;
});

const isError = computed<boolean | undefined>(() => {
  if ((props.error?.length || 0) > 0) {
    return true;
  }
  return undefined;
});

watch(filter, (newValue: string) => {
  if (newValue.length >= 3) {
    emit('externalSearch', newValue);
  }

  if (newValue.length === 0) {
    emit('externalSearch');
  }
});

function handleClear(e: Event): void {
  e.preventDefault();
  e.stopPropagation();
  model.value = [];
}

function isItemSelected(itemValue: string): boolean {
  const isSelected = model.value.find((selected) => selected === itemValue);
  return !!isSelected;
}

function isGroupSelected(group: SelectGroup): boolean | undefined {
  let atLeastOneItemSelected = false;
  let areAllItemsSelected = true;

  if (!group.items) {
    return isItemSelected(group.value);
  }

  if (group.items.length === 1) {
    return isItemSelected(group.items[0].value);
  }

  group.items.forEach((item) => {
    if (isItemSelected(item.value)) {
      atLeastOneItemSelected = true;
    } else if (!isItemSelected(item.value)) {
      areAllItemsSelected = false;
    }
  });

  if (atLeastOneItemSelected && !areAllItemsSelected) {
    return undefined;
  }

  return atLeastOneItemSelected && areAllItemsSelected;
}

function isSelectedAllGroups(): boolean | undefined {
  let allGroupSelected: boolean | undefined = true;

  props.options.forEach((group) => {
    if (!isGroupSelected(group)) {
      allGroupSelected = false;
    }
  });

  if (!allGroupSelected && model.value.length > 0) {
    allGroupSelected = undefined;
  }

  return allGroupSelected;
}

function filterGroupsAndItems(
  newFilter: string,
  options: SelectGroup[],
  showSearch: boolean,
): SelectGroup[] {
  if (newFilter.length === 0 && showSearch) {
    return options;
  }

  let filteredItemGroups = [] as SelectGroup[];

  filteredItemGroups = options.map((group) => {
    if (group.items) {
      const filteredItems = group.items.filter((item) =>
        item.name.toLowerCase().includes(newFilter.toLowerCase()),
      );

      return {
        ...group,
        items: filteredItems,
      };
    }
    return {
      ...group,
    };
  });

  if (
    filteredItemGroups.filter((group) => group.items && group.items.length > 0)
      .length === 0
  ) {
    return options.filter((group) =>
      group.groupLabel.toLowerCase().includes(newFilter.toLowerCase()),
    );
  }

  return filteredItemGroups.filter(
    (group) => group.items && group.items.length > 0,
  );
}

const filteredOptions = computed<SelectGroup[]>(() => {
  return filterGroupsAndItems(filter.value, props.options, isSearchShown.value);
});

function toggleAllItems(group: SelectGroup): void {
  group.items?.forEach((itemSelected) => {
    if (!isItemSelected(itemSelected.value)) {
      selectedItems.value.push(itemSelected.value);
    } else {
      const userIndex = selectedItems.value.findIndex(
        (selected) => selected === itemSelected.value,
      );
      selectedItems.value.splice(userIndex, 1);
    }
  });
  model.value = selectedItems.value;
}

function unselectAllSelectedItems(section: SelectGroup): void {
  section.items?.forEach((itemSelected) => {
    const hasSelectedIndex = selectedItems.value.findIndex(
      (item) => item === itemSelected.value,
    );
    if (hasSelectedIndex >= 0) {
      selectedItems.value.splice(hasSelectedIndex, 1);
    }
  });
  model.value = selectedItems.value;
}

function selectItem(itemValue: string): void {
  const itemSelectedIndex = model.value.findIndex(
    (selected) => selected === itemValue,
  );

  if (itemSelectedIndex < 0) {
    selectedItems.value.push(itemValue);
  } else {
    selectedItems.value.splice(itemSelectedIndex, 1);
  }

  model.value = selectedItems.value;
}

function selectItensInSection(group: SelectGroup): void {
  if (!group.items) {
    if (!isItemSelected(group.value)) {
      selectItem(group.value);
    } else {
      const userIndex = selectedItems.value.findIndex(
        (selected) => selected === group.value,
      );
      selectedItems.value.splice(userIndex, 1);
    }
  } else if (isGroupSelected(group) === undefined) {
    unselectAllSelectedItems(group);
  } else {
    toggleAllItems(group);
  }
}

function selectAllGroups(): void {
  if (isSelectedAllGroups() || isSelectedAllGroups() === undefined) {
    model.value = [];
  } else {
    props.options.forEach((group) => selectItensInSection(group));
  }
}
</script>

<template>
  <q-select
    ref="qselect"
    v-model="model"
    :options="localSearch ? filteredOptions : options"
    :dropdown-icon="symRoundedKeyboardArrowDown"
    :input-debounce="inputDebounce"
    :class="[
      'app-select-section',
      `app-select-section--${size}`,
      { 'app-select-section--width': width },
    ]"
    input-class="app-select-section__input"
    :popup-content-class="`app-select__popup-content ${popupContentClass} app-select__popup-content-grouped--all`"
    options-dense
    :clearable="false"
    borderless
    no-error-icon
    data-testid="app-select-input"
    :error="isError"
    @popup-show="handleSelectPopupShow($el, '--appSelectMaxWidth')"
  >
    <template #append>
      <slot v-if="$slots.append" name="before-options" />
      <template v-else>
        <AppClearIcon v-if="showClearable" @click="handleClear" />
      </template>
    </template>
    <template v-if="showPlaceholder" #selected>
      <span key="placeholder" class="app-select-section__placeholder">
        {{ placeholder }}
      </span>
    </template>
    <template v-else-if="filterLabel" #selected>
      <span key="filterLabel" class="app-select-section__label">
        {{ filterLabel || $t('common.filters.options', model.length) }}
      </span>
    </template>

    <template #before-options>
      <div
        v-if="isSearchShown"
        class="app-select__option__search-wrap app-select-section-search__border"
      >
        <AppSearch v-model="filter" select-search />
      </div>
      <div v-if="options.length > 0" class="app-select-group__container__all">
        <q-item
          clickable
          :model-value="isSelectedAllGroups()"
          @click="selectAllGroups()"
        >
          <q-item-section>
            <q-checkbox
              :model-value="isSelectedAllGroups()"
              @click="selectAllGroups()"
            />
          </q-item-section>
          <q-item-section class="app-select-group__label">
            <q-item-label class="app-select-grouped__title">
              {{ t('common.filters.all') }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <AppSeparator class="app-select-section__separator" />
      </div>
    </template>

    <template #no-option>
      <div
        v-if="isSearchShown"
        class="app-select__option__search-wrap app-select-section-search__border"
      >
        <AppSearch v-model="filter" select-search />
      </div>
      <q-item
        class="q-item--dense app-select__option app-select__option--not-found"
      >
        <AppLoading v-if="isLoadingExternalSearch" />

        <q-item-section>
          <q-item-label>
            {{ $t('common.filters.noOptionFound') }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>

    <template #option="{ opt, index }">
      <AppLoading v-if="isLoadingExternalSearch" />
      <div v-else>
        <AppSeparator
          v-if="index !== 0"
          class="app-select-section__separator"
        />
        <q-item
          clickable
          :model-value="isGroupSelected(opt)"
          @click="selectItensInSection(opt)"
        >
          <q-item-section>
            <q-checkbox
              :key="index"
              :model-value="isGroupSelected(opt)"
              @click="selectItensInSection(opt)"
            />
          </q-item-section>
          <q-item-section class="app-select-group__label">
            <q-item-label class="app-select-grouped__title">
              <b>
                {{ opt.groupLabel }}
              </b>
            </q-item-label>
            <q-item-label class="app-select-group__label--append">
              {{ opt.groupAppendLabel }}
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-item
          v-for="subItem in opt.items"
          :key="subItem.value"
          clickable
          :model-value="isItemSelected(subItem.value)"
          @click="selectItem(subItem.value)"
        >
          <q-item-section>
            <q-checkbox
              :key="subItem.value"
              :model-value="isItemSelected(subItem.value)"
              @click="selectItem(subItem.value)"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label>
              {{ subItem.name }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </div>
    </template>
    <template v-if="error" #error>
      <AppErrorMessage :error="error" />
    </template>
  </q-select>
</template>

<style scoped lang="scss">
.app-select-section :deep(.q-field__control) {
  border-radius: 4px;
  padding: 1px;
  border: 1px solid $gray-400;
  background-color: $white;
  box-sizing: border-box;
}

.app-select-section--M :deep(.q-field__native) {
  height: 44px;
}

.app-select-section--M :deep(.app-select-section__label),
.app-select-section--M :deep(.app-select-section__input),
.app-select-section--M :deep(.app-select-section__placeholder) {
  line-height: 34px;
}

.app-select-section :deep(.app-search .app-search__input) {
  flex-grow: 1;
}

.app-select-section:hover :deep(.q-field__control),
.app-select-section.q-field--focused :deep(.q-field__control) {
  border: 1px solid $primary-color;
}

.app-select-section:hover:not(.q-field--disabled) :deep(.q-field__append),
.app-select-section.q-field--focused :deep(.q-field__append) {
  color: $primary-color;
}

.app-select-section.q-field--error :deep(.q-field__control) {
  border: 1px solid $red-500;
}

.app-select-section :deep(.q-field__native) {
  padding: 5px 0 5px 12px;
  align-items: center;
}

.app-select-section :deep(.q-field__append) {
  height: unset;
  padding: 0 8px;
  color: $gray-800;
}

.app-select-section__placeholder {
  color: $gray-500;
}

.app-select-section__label,
.app-select-section__placeholder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-select-section.q-field--disabled :deep(.q-field__control) {
  background-color: $gray-50;
  border-color: $gray-50;
}

.app-select-section-item__line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.app-select-section-item__title {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.24px;
  color: $gray-800;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.app-select-section-item__subtitle {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: $gray-500;
}

.app-select-section-title-subtitle__container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.app-select-section__separator {
  margin-top: 4px;
}

.app-select-section-item-list {
  display: flex;
  gap: 8px;
  padding-bottom: 2px;
  flex-direction: row;
  cursor: pointer;
}

.app-select-section-item-list:hover {
  background: $gray-25;
}

.app-select-section-itens__container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.app-select-section-item__name {
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.app-select-section-search__border {
  border-bottom: 0px;
}

:deep(.q-item__section--main) {
  flex: none;
}

.app-select-group__label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

:deep(.q-item__label + .q-item__label) {
  margin-top: 0px;
}

.app-select-group__label--append {
  color: $gray-500;
}

.app-select-grouped__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 172px;
}

.app-select-group__container__all {
  padding: 0 16px;
}

.app-select-section--width {
  width: v-bind(width);
}

:deep(.q-checkbox__inner--truthy),
:deep(.q-checkbox__inner--indet) {
  color: $primary-color;
}
</style>
