
import { defineComponent, PropType } from 'vue';
import { matInfo } from '@quasar/extras/material-icons';
import { AppLabelSize } from '@/shared/types/generic';
import AppInformation from '../AppInformation/AppInformation.vue';

const __default__ = defineComponent({
  name: 'AppLabel',

  components: {
    AppInformation,
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    information: {
      type: String,
      default: undefined,
    },
    size: {
      type: String as PropType<AppLabelSize>,
      default: 'medium',
    },
    tooltipWidth: {
      type: String,
      default: undefined,
    },
    isRequired: Boolean,
    marginBottom: {
      type: String,
      default: '4px',
    },
    withoutBold: {
      type: Boolean,
      default: false,
    },
    hasAsterisk: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      matInfo,
    };
  },

  computed: {
    hasInformation() {
      return !!this.information || this.$slots.information;
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "5a046daa": (_ctx.marginBottom)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__