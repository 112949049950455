<template>
  <q-field
    v-bind="$attrs"
    class="app-field"
    data-testid="app-field"
    :error="hasError"
    no-error-icon
    borderless
  >
    <div class="app-field__content" data-testid="app-field-content">
      <slot />
    </div>
    <template #error>
      <slot name="error" />
    </template>
  </q-field>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppField',

  props: {
    error: {
      type: String,
      default: '',
    },
  },

  computed: {
    hasError() {
      return this.error.length > 0;
    },
  },
});
</script>

<style scoped lang="scss">
.app-field__content {
  flex: 1;
}
</style>
