<template>
  <q-separator
    :class="computedClass"
    v-bind="$props"
    data-testid="app-separator"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppSeparator',

  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    inset: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: String,
      default: undefined,
    },
    verticalMargin: {
      type: String,
      default: '5px',
    },
  },

  computed: {
    computedClass(): string {
      let result = 'app-separator';
      if (this.vertical) {
        result += ' app-separator--vertical';
      }
      return result;
    },
  },
});
</script>

<style scoped lang="scss">
.app-separator {
  background-color: $gray-50;
  margin: v-bind(margin);
}

.app-separator--vertical {
  background-color: $primary-background;
  margin-top: v-bind(verticalMargin);
  margin-bottom: v-bind(verticalMargin);
}
</style>
