<template>
  <div class="app-accordion__container" data-testid="app-accordion-container">
    <div
      :class="[
        'app-accordion__header',
        { 'app-accordion__header--bold': expandedQuestion },
      ]"
      @click="handlerExpandedQuestion"
      @keydown="handlerExpandedQuestion"
    >
      <div
        class="app-accordion__header-content"
        data-testid="app-accordion-header-content"
      >
        <slot name="header" />
      </div>

      <IconArrowDown
        :class="[
          'app-accordion__arrow',
          { 'app-accordion__arrow--active': expandedQuestion },
        ]"
      />
    </div>
    <AppCollapsible :expanded="expandedQuestion">
      <AppSeparator class="app-accordion__separator" />
      <div class="app-accordion__content" data-testid="app-accordion-content">
        <slot />
      </div>
    </AppCollapsible>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ReactionType } from '@/shared/types/generic';
import IconArrowDown from '@/assets/icons/icon-arrow-down.vue';
import AppCollapsible from '../AppCollapsible/AppCollapsible.vue';
import AppSeparator from '../AppSeparator/AppSeparator.vue';

export default defineComponent({
  name: 'AppAccordion',
  components: { AppCollapsible, AppSeparator, IconArrowDown },

  data() {
    return {
      expandedQuestion: false,
      ReactionType,
    };
  },

  methods: {
    handlerExpandedQuestion(): void {
      this.expandedQuestion = !this.expandedQuestion;
    },
  },
});
</script>

<style scoped lang="scss">
.app-accordion__container {
  display: flex;
  flex-direction: column;
  background: $white;
  border: 1px solid $gray-50;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px #f6f6f6;
  min-height: 64px;

  justify-content: center;
}

.app-accordion__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  gap: 16px;
}

.app-accordion__header-content {
  display: flex;
  flex-grow: 1;
}

.app-accordion__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-height: 72px;
}

.app-accordion__separator {
  margin-right: 20px;
  margin-left: 20px;
}

.app-accordion__arrow {
  transition: all 0.5s;
  width: 13px;
  height: 13px;
}

.app-accordion__arrow--active {
  transform: rotate(180deg);
}
</style>
