<template>
  <div class="app-badge" :style="style" data-testid="app-badge">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppBadge',

  props: {
    color: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    style() {
      return `background-color: ${this.color}`;
    },
  },
});
</script>

<style scoped lang="scss">
.app-badge {
  width: 90px;
  height: 24px;
  color: $gray-800;
  font-size: 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
