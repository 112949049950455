
import { ProgressBarColor } from '@/shared/types/generic';
import { defineComponent } from 'vue';

const __default__ = defineComponent({
  name: 'AppProgressBar',

  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    maxSteps: {
      type: Number,
      required: true,
    },
    showSteps: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: ProgressBarColor.PRIMARY,
    },
  },

  computed: {
    model(): number {
      return this.modelValue;
    },

    barWidth(): string {
      const value = this.model > this.maxSteps ? this.maxSteps : this.model;
      return `${(value * 100) / this.maxSteps}%`;
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3718edbe": (_ctx.barWidth),
  "794b1a10": (_ctx.color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__