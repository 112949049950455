import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppClearIcon = _resolveComponent("AppClearIcon")!

  return (_ctx.showMessage)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
      'app-message',
      `app-message--${_ctx.type}`,
      { 'app-message--no-padding': _ctx.noPadding },
    ])
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_AppIcon, {
              key: 0,
              name: _ctx.icon,
              size: _ctx.size,
              padding: _ctx.imagePadding
            }, null, 8, ["name", "size", "padding"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        (_ctx.closable)
          ? (_openBlock(), _createBlock(_component_AppClearIcon, {
              key: 1,
              class: "app-message__close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMessage = false))
            }))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}