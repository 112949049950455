<script setup lang="ts">
import { computed, useSlots } from 'vue';
import { type Component } from 'vue';
import AppInformation from '../AppInformation/AppInformation.vue';

interface Props {
  backgroundColor: string;
  text: string;
  icon: Component;
  iconColor: string;
  percentage: number;
  backgroundProgressColor: string;
  textColor: string;
  tooltipMaxWidth: string;
}
const slots = useSlots();
const props = defineProps<Props>();

const percentageInString = computed(() => {
  return `${props.percentage}%`;
});
</script>
<template>
  <div class="app-status-indicator">
    <div class="app-status-indicator__container">
      <div class="app-status-indicator__container__content">
        <component
          :is="props.icon"
          :color="props.iconColor"
          class="app-status-indicator__container__content-icon"
        />
        <span>{{ props.text }}</span>
      </div>

      <div class="app-status-indicator__container__progress"></div>
    </div>
    <AppInformation
      v-if="slots.tooltip"
      size="16px"
      class="app-status-indicator__information"
      :tooltip-max-width="tooltipMaxWidth"
    >
      <slot name="tooltip" />
    </AppInformation>
  </div>
</template>

<style scoped lang="scss">
.app-status-indicator {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 14px;
  height: 32px;
}
.app-status-indicator__container {
  background-color: v-bind(backgroundProgressColor);
  flex-grow: 1;
  border-radius: 4px;

  justify-content: center;

  position: relative;
}

.app-status-indicator__container__content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 8px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: v-bind(textColor);
}

.app-status-indicator__container__progress {
  position: absolute;
  top: 0;
  width: v-bind(percentageInString);
  height: 100%;
  border-radius: 4px;
  align-content: center;
  background-color: v-bind(backgroundColor);
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-status-indicator__container__content-icon {
  width: 16px;
  height: 16px;
}

.app-status-indicator__information {
  height: 100%;
}
</style>
