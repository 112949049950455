import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!

  return (_openBlock(), _createBlock(_component_q_tooltip, _mergeProps({
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event))
  }, _ctx.$props, {
    anchor: "bottom right",
    self: "center left",
    "transition-show": "fade",
    "transition-hide": "fade",
    offset: [0, 0],
    class: "app-tooltip",
    "data-testid": "app-tooltip",
    "max-width": _ctx.maxWidth
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["modelValue", "max-width"]))
}