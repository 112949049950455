import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "wrapper",
  class: "app-collapsible",
  "data-testid": "app-collapsible"
}
const _hoisted_2 = {
  ref: "inner",
  class: "app-collapsible__inner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "app-collapsible",
    onEnter: _ctx.onEnter,
    onAfterEnter: _ctx.onAfterEnter,
    onBeforeLeave: _ctx.onBeforeLeave,
    onLeave: _ctx.onLeave
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ], 512)
      ], 512), [
        [_vShow, _ctx.expanded]
      ])
    ]),
    _: 3
  }, 8, ["onEnter", "onAfterEnter", "onBeforeLeave", "onLeave"]))
}