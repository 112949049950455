<template>
  <div class="app-no-data" data-testid="app-no-data">
    <AppIcon
      v-if="icon"
      :name="icon"
      class="app-no-data__icon"
      data-testid="app-no-data-icon"
    />
    <span data-testid="app-no-data-message">
      {{ message || $t('common.noSearchResuls') }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import { outlinedInfo } from '@quasar/extras/material-icons-outlined';

export default defineComponent({
  name: 'AppNoData',
  components: { AppIcon },

  props: {
    message: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: outlinedInfo,
    },
  },

  data() {
    return {
      outlinedInfo,
    };
  },
});
</script>

<style scoped lang="scss">
.app-no-data {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 28px;
  border-radius: 16px;
  font-size: 14px;
  background-color: $white;
  color: $gray-800;
}

.app-no-data__icon {
  font-size: 20px;
  transform: scale(1.2);
}
</style>
