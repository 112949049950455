<template>
  <div
    v-if="showMessage"
    :class="[
      'app-message',
      `app-message--${type}`,
      { 'app-message--no-padding': noPadding },
    ]"
  >
    <AppIcon v-if="icon" :name="icon" :size="size" :padding="imagePadding" />

    <slot />
    <AppClearIcon
      v-if="closable"
      class="app-message__close"
      @click="showMessage = false"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AppMessageType } from '@/shared/types/components';
import AppClearIcon from '../AppClearIcon/AppClearIcon.vue';
import AppIcon from '../AppIcon/AppIcon.vue';

export default defineComponent({
  name: 'AppMessage',

  components: { AppClearIcon, AppIcon },

  props: {
    icon: {
      type: String,
      default: undefined,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<AppMessageType>,
      default: 'info',
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    imagePadding: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '20px',
    },
  },

  data() {
    return {
      showMessage: true,
    };
  },
});
</script>
<style scoped lang="scss">
.app-message {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: fit-content;
  border-radius: 8px;
  color: $gray-800;
}

.app-message--no-padding {
  padding: 0px;
}

.app-message.app-message--info {
  background-color: $blue-50;
  align-items: flex-start;
  width: 100%;
}

.app-message.app-message--empty {
  background-color: $gray-25;
  align-items: flex-start;
  width: 100%;
}

.app-message.app-message--warning .q-icon {
  color: $yellow-500;
}
</style>
