<script setup lang="ts">
import { AppButton, BaseTooltip } from '@/plugins/commons';
import IconArrowDown from '@/assets/icons/icon-arrow-down.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

interface Props {
  isLastStep: boolean;
  isFirstStep: boolean;
}

interface Emits {
  (e: 'continue'): void;
  (e: 'previous'): void;
  (e: 'cancel'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
</script>

<template>
  <div class="app-wizard-footer">
    <AppButton
      type="tertiary"
      size="L"
      :label="t('labels.common.cancel')"
      @click="emit('cancel')"
    >
      <BaseTooltip>
        {{ $t('games.challengeWizard.cancel') }}
      </BaseTooltip>
    </AppButton>

    <div class="app-wizard-footer__flow-buttons">
      <AppButton
        v-if="!props.isFirstStep"
        type="secondary"
        size="L"
        @click="emit('previous')"
      >
        <IconArrowDown
          width="16"
          height="16"
          class="app-wizard-footer__arrow"
        />
        {{ t('labels.common.previous') }}
      </AppButton>
      <AppButton
        v-if="!props.isLastStep"
        type="primary"
        size="L"
        :label="t('labels.common.continue')"
        @click="emit('continue')"
      >
        <IconArrowDown
          width="16"
          height="16"
          class="app-wizard-footer__arrow--front"
        />
      </AppButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-wizard-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.app-wizard-footer__arrow {
  transform: rotateZ(90deg);
}

.app-wizard-footer__arrow--front {
  transform: rotateZ(-90deg);
}

.app-wizard-footer__flow-buttons {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
</style>
