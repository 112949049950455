<template>
  <q-drawer
    ref="appSidebar"
    v-bind="$props"
    v-model="appSidebarOpen"
    side="right"
    class="app-sidebar"
    overlay
    data-testid="app-sidebar-drawer"
    behavior="desktop"
    persistent
    :width="576"
  >
    <slot />
  </q-drawer>
</template>

<script lang="ts">
import { ComponentPublicInstance, defineComponent } from 'vue';

export default defineComponent({
  name: 'AppSidebar',

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'clickOutside'],

  computed: {
    appSidebarOpen: {
      get() {
        return this.$props.modelValue;
      },
      set(newAppSidebarOpenState: boolean) {
        this.$emit('update:modelValue', newAppSidebarOpenState);
      },
    },
  },

  mounted() {
    window.addEventListener('mousedown', this.handleMouseDown);
  },

  unmounted() {
    window.removeEventListener('mousedown', this.handleMouseDown);
  },

  methods: {
    handleMouseDown(event: MouseEvent) {
      if (this.appSidebarOpen) {
        const target = event.target as HTMLElement;

        const appSidebar = (this.$refs.appSidebar as ComponentPublicInstance)
          .$el as HTMLElement;

        const documentPositionComparison =
          target.compareDocumentPosition(appSidebar);

        if (
          documentPositionComparison !== Node.DOCUMENT_POSITION_PRECEDING &&
          documentPositionComparison !== Node.DOCUMENT_TYPE_NODE
        ) {
          this.$emit('clickOutside');
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
:deep(.q-drawer__backdrop) {
  opacity: 0;
}

:deep(.app-sidebar) {
  background-color: $white;
  display: flex;
  flex-direction: column;
  box-shadow: -1px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px 0 0 0;
}

:deep(.q-drawer) {
  top: 0px;
}
</style>
