<template>
  <q-inner-loading v-bind="$props" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppInnerLoading',
});
</script>
