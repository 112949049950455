<template>
  <div class="app-loading-row__content" data-testid="app-loading-row">
    <q-skeleton
      v-for="(element, index) in numberOfSkeletons"
      :key="`${index + element}`"
      :type="skeletons[index].type"
      :style="skeletons[index].style"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { SkeletonStyle } from '@/shared/types/generic';

export default defineComponent({
  name: 'AppLoadingRow',
  props: {
    numberOfSkeletons: { type: Number, required: true },
    skeletons: { type: Array as PropType<SkeletonStyle[]>, required: true },
  },
});
</script>

<style scoped lang="scss">
.app-loading-row__content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 56px;
  background-color: $white;
  border-bottom: 1px solid #eeeeee;
}
</style>
