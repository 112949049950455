<template>
  <div class="app-progress-bar" data-testid="app-progress-bar">
    <span
      class="app-progress-bar__indicator"
      data-testid="app-progress-bar-indicator"
    ></span>
    <p
      v-if="showSteps"
      class="app-progress-bar__text"
      data-testid="app-progress-bar-text"
    >
      {{ $t('common.step', [model, maxSteps]) }}
    </p>
  </div>
</template>

<script lang="ts">
import { ProgressBarColor } from '@/shared/types/generic';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppProgressBar',

  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    maxSteps: {
      type: Number,
      required: true,
    },
    showSteps: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: ProgressBarColor.PRIMARY,
    },
  },

  computed: {
    model(): number {
      return this.modelValue;
    },

    barWidth(): string {
      const value = this.model > this.maxSteps ? this.maxSteps : this.model;
      return `${(value * 100) / this.maxSteps}%`;
    },
  },
});
</script>

<style scoped lang="scss">
.app-progress-bar__indicator {
  position: relative;
  display: block;
  height: 6px;
  width: 100%;
  border-radius: 40px;
  background-color: $gray-50;
}

.app-progress-bar__indicator::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: v-bind(barWidth);
  border-radius: 40px;
  background-color: v-bind(color);
  transition: all 0.3s;
}

.app-progress-bar__text {
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;
  color: $gray-500;
  margin-bottom: 0;
}
</style>
