<template>
  <div v-if="status" :class="computedClass">
    <AppTooltip
      v-if="tooltip && startDate"
      anchor="top middle"
      self="bottom middle"
      :offset="[5, 5]"
      max-width="200px"
    >
      <div class="app-store-item-status-tooltip">
        <span>
          {{
            getStatusTooltip(
              status,
              startDate,
              endDate,
              profileStore?.userProfileSite?.site.timezone.ianaTimezone || '',
            )
          }}
        </span>
      </div>
    </AppTooltip>
    <AppBadge
      :color="getStoreStatusColor(status)"
      data-testid="store-item-status"
    >
      {{ $t(`store.filters.status.${status}`) }}
    </AppBadge>
    <AppIcon
      v-if="showCalendarIcon(status, endDate)"
      class="app-store-item-status-icon"
      :name="outlinedEvent"
      size="19px"
    />
  </div>
</template>

<script lang="ts">
import {
  getStatusTooltip,
  showCalendarIcon,
} from '@/shared/helpers/store/storeFunctions';
import getStoreStatusColor from '@/shared/helpers/getStoreStatusColor/getStoreStatusColor';
import { StoreStatus } from '@/shared/types/store';
import { defineComponent, PropType } from 'vue';
import { outlinedEvent } from '@quasar/extras/material-icons-outlined';
import AppTooltip from '@/components/app/AppTooltip/AppTooltip.vue';
import AppBadge from '@/components/app/AppBadge/AppBadge.vue';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import useProfileStore from '@/store/profile/useProfileStore';
import { mapStores } from 'pinia';

export default defineComponent({
  name: 'AppStoreItemStatus',

  components: {
    AppTooltip,
    AppBadge,
    AppIcon,
  },

  props: {
    status: {
      type: String as PropType<StoreStatus>,
      default: undefined,
    },
    startDate: {
      type: Number,
      default: undefined,
    },
    endDate: {
      type: Number,
      default: undefined,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      outlinedEvent,
    };
  },

  computed: {
    ...mapStores(useProfileStore),

    computedClass() {
      let result = 'app-store-item-status';
      if (this.tooltip) {
        result += ' app-store-item-status--tooltip';
      }
      return result;
    },
  },

  methods: {
    showCalendarIcon,
    getStoreStatusColor,
    getStatusTooltip,
  },
});
</script>

<style scoped lang="scss">
.app-store-item-status {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
}
.app-store-item-status--tooltip {
  cursor: pointer;
}
.app-store-item-status-icon {
  color: $gray-500;
}
</style>
